import React, { Suspense, lazy } from 'react'
import { Route,
         Switch,
         withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { StoneProvider } from './myModules/reactStone.js'

// import * as a from './redux/actions'
import * as f from './utils/functions.js'
import enJson from './data/en.json'
import Header from './components/Header'


const NoFound = lazy(() => import('./components/NoFound'))

const Home = lazy(() => import('./components/Home/Home.js'))
const Projects = lazy(() => import('./components/Projects/Projects'))
const ProjectsId = lazy(() => import('./components/ProjectsId/ProjectsId'))
const Publish = lazy(() => import('./components/Publish/Publish'))
const Portfolio = lazy(() => import('./components/Portfolio/Portfolio'))
const Freelancers = lazy(() => import('./components/Freelancers/Freelancers'))
const Login = lazy(() => import('./components/Login'))
const Logout = lazy(() => import('./components/Logout'))
const Registration = lazy(() => import('./components/Registration'))
const Recover = lazy(() => import('./components/Recover'))

const Users = lazy(() => import('./components/Users'))
const UsersPortfolio = lazy(() => import('./components/UsersPortfolio/UsersPortfolio'))
const UsersPortfolioId = lazy(() => import('./components/UsersPortfolioId/UsersPortfolioId'))

const UsersProjects = lazy(() => import('./components/UsersProjects/UsersProjects'))

const UsersReviews = lazy(() => import('./components/UsersReviews/UsersReviews'))
const UsersInfo = lazy(() => import('./components/UsersInfo/UsersInfo'))
const UsersSettings = lazy(() => import('./components/UsersSettings'))
const UsersSettingsMain = lazy(() => import('./components/UsersSettingsMain'))
const UsersSettingsDelete = lazy(() => import('./components/UsersSettingsDelete'))

const MyProjects = lazy(() => import('./components/MyProjects'))
const Offers = lazy(() => import('./components/Offers'))
const Orders = lazy(() => import('./components/Orders'))

const OrdersId = lazy(() => import('./components/OrdersId/OrdersId'))
const Messages = lazy(() => import('./components/Messages'))
const MessagesId = lazy(() => import('./components/MessagesId'))

const OfferOrderId = lazy(() => import('./components/OfferOrderId/OfferOrderId'))

const Confirmation = lazy(() => import('./components/Confirmation'))

const SiteRules = lazy(() => import('./components/SiteRules.js'))



// let App = (props) => {
class App extends React.Component {
  constructor(props) {
    super(props)

    // 2. add the global callback function
    // console.log('1window.grecaptcha = ', window.grecaptcha)
    window.onloadCallback = () => {
      window.grecaptchaLoaded = true
      // console.log('2window.grecaptcha = ', window.grecaptcha)
    }
    // window.onerror = function(message, source, lineno, colno, error) {
    //   return true
    // }
    window.onerror = function(err) {
      console.log('###err = ', err)
      return true
    }

    // window.onbeforeunload = function(){
    //   alert('hello')
    // }

    // var catalogs = {
    //   [this.props.globals.locale]: {
    //     "plural-forms": "nplurals=2; plural=(n != 1);",
    //     "messages": {
    //       "Hello World": [" "],
    //       "Hello {name}": [" "]
    //     }
    //   }
    //   // "ru": {
    //   //   "plural-forms": "nplurals=2; plural=(n > 1);",
    //   //   "messages": {
    //   //     "Hello World": ["Привет мир"],
    //   //     "Hello {name}": ["Привет {name}"]
    //   //   }
    //   // },
    //   // "ua": {
    //   //   "plural-forms": "nplurals=2; plural=(n != 1);",
    //   //   "messages": {
    //   //     "Hello World": ["Привiт cвiт"],
    //   //     "Hello {name}": ["Привiт {name}"]
    //   //   }
    //   // },
    // }

    var catalogs = {
      'ru': enJson['en'],
      'ua': enJson['en'],
      "in": enJson['en'],
      "cn": enJson['en'],
      "es": enJson['en'],
      "de": enJson['en'],
      "pl": enJson['en'],
      "fr": enJson['en'],
    }

    this.state = {
      // locale: this.props.globals.locale,
      catalogs
    }
  }

  componentDidMount() {
    // console.log('this.props = ', this.props)
    let params = new URLSearchParams(this.props.location.search)
    let getSsr = params.get('getSsr')
    // console.log('params = ', params)
    // console.log('params = ', params.get('getSsr'))
    // console.log('params = ', params.get('something'))
    // if (getSsr === null) {
    //   window.addEventListener('load', this.loadData)    
    // }

    // 2. include a json file
    if (this.props.globals.locale !== 'en') {
      this.getTranslations(this.props.globals.locale)
    }
  }

  // loadData = () => {
  //   // 1. include a recaptcha API
  //   let head = document.getElementsByTagName('head')[0]
  //   let script = document.createElement('script')
  //   script.setAttribute('data-main', 'js/main');
  //   script.src = `https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=${f.getReCaptchaLocale(this.props.globals.locale)}`
  //   script.type = "text/javascript"
  //   script.async = true 
  //   script.defer = true
  //   script.id = 'my-script'

  //   // console.log('script = ', script)
  //   head.appendChild(script)
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   // console.log('componentDidUpdate')
  // }

  componentWillUnmount() {
    // remove a recaptcha API
    f.deleteReCaptcha()
  }

  getTranslations = (locale) => {
    // console.log('locale = ', locale)
    fetch(`/locale/${locale}.json`, {
      method: 'GET',
      headers: {
        // 'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(r => {
        this.isFetching = false
        if (!r.ok) {
          return Promise.reject()
        } else {
          return r.json()
        }
      })
      .then(json => {
        // console.log('1#### json = ', json)
        if(json.error) {
          return this.setState({ ...json })
        } else {
          this.setState({
            locale,
            catalogs: json
          })
        }
      })
      .catch(error => {
        console.log(console.log('error = ', error));
        return this.setState({ error })
      })
  }

  render() {
    // console.log('this.props = ', this.props)
    // console.log('RENDER = ', this.state.locale)
    let { props } = this
    // let { /*locale,*/ expiresIn, token, dispatch } = props
    // let { locale } = this.state
    let { locale } = this.props.globals
    // console.log('locale = ', locale)

    // if (locale === 'en' ||
    //     locale === 'ru' ||
    //     locale === 'ua' ||
    //     locale === 'in' ||
    //     locale === 'cn' ||
    //     locale === 'es' ||
    //     locale === 'de' ||
    //     locale === 'pl' ||
    //     locale === 'fr'
    // ) {
      return (
        <StoneProvider
          catalogs={this.state.catalogs}
          locale={this.props.globals.locale}
          className="two"
        >

          <Suspense fallback={
            <div>
              <Header {...this.props} MMOOCounters={this.state.MMOOCounters} />
              <section className="PortfolioLogged">
                <div>
                  
                </div>
              </section>
              
            </div>
          }>

            <Switch className="one">
              <Route path={"/"} exact render={propsRouter => 
                <Home {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/"} exact render={propsRouter =>
                <Home {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/projects"} exact render={propsRouter =>
                <Projects {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/projects/:id"} exact render={propsRouter =>
                <ProjectsId {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/publish"} exact render={propsRouter =>
                <Publish {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/portfolio"} exact render={propsRouter =>
                <Portfolio {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/freelancers"} exact render={propsRouter =>
                <Freelancers {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/login"} exact render={propsRouter =>
                <Login {...propsRouter} />
              } />
              <Route path={"/" + locale + "/logout"} exact render={propsRouter =>
                <Logout {...propsRouter} />
              } />
              <Route path={"/" + locale + "/registration"} exact render={propsRouter =>
                <Registration {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/recover"} exact render={propsRouter =>
                <Recover {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/users/"} exact render={propsRouter =>
                <Users {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/users/:login/"} exact render={propsRouter =>
                <UsersPortfolio {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/users/:login/portfolio/:id/"} exact render={propsRouter =>
                <UsersPortfolioId {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/users/:login/projects/"} exact render={propsRouter =>
                <UsersProjects {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/users/:login/reviews/"} exact render={propsRouter =>
                <UsersReviews {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/users/:login/info/"} exact render={propsRouter =>
                <UsersInfo {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/users/:login/settings/"} exact render={propsRouter =>
                <UsersSettings {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/users/:login/settings/main/"} exact render={propsRouter =>
                <UsersSettingsMain {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/users/:login/settings/delete/"} exact render={propsRouter =>
                <UsersSettingsDelete {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/myprojects/"} exact render={propsRouter =>
                <MyProjects {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/offers/"} exact render={propsRouter =>
                <Offers {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/orders/"} exact render={propsRouter =>
                <Orders {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/orders/:id/"} exact render={propsRouter =>
                <OrdersId {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/messages/"} exact render={propsRouter =>
                <Messages {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/messages/:id/"} exact render={propsRouter =>
                <MessagesId {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/offerorder/:id/"} exact render={propsRouter =>
                <OfferOrderId {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/confirmation/"} exact render={propsRouter =>
                <Confirmation {...propsRouter} {...props} />
              } />
              <Route path={"/" + locale + "/siterules/"} exact render={propsRouter =>
                <SiteRules {...propsRouter} {...props} />
              } />
              <Route path='*' render={propsRouter =>
                <NoFound {...propsRouter} {...props} />
              } />
            </Switch>

          </Suspense>

        </StoneProvider>
      )
    // } else {
    //   return (
    //     <Route path='*' render={propsRouter =>
    //       <NoFound {...propsRouter} {...props} />
    //     } />
    //   )
    // }
  }
}

const mapStateToProps = state => {
  // const { expiresIn, token } = state.globals
  // return { expiresIn, token }
  const { globals } = state
  return { globals }
}

App = connect(mapStateToProps)(App)
App = withRouter(App)


export default App