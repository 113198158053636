import React from 'react'
import Stone from "stonejs";

let catalogs = {}

let catalogId = 0

export class StoneProvider extends React.Component {
  render() {
    catalogId++
    catalogs = this.props.catalogs
    Stone.addCatalogs(this.props.catalogs)
    Stone.setLocale(this.props.locale)
    // console.log('this.props.catalogs = ', this.props.catalogs)
    // console.log('this.props.locale = ', this.props.locale)
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}



export function withStone(OldComponent) {
  class NewComponent extends React.Component {
    // constructor(props) {
    //   super(props)
    //   this.state = {}
    // }
    render() {
      return (
        <OldComponent
          Stone={Stone}
          catalogs={catalogs}
          catalogId={catalogId}
          {...this.props}
        />
      )
    }
  }
  return Object.assign(NewComponent, OldComponent)
}



export function getStone() {
  return Stone
}