import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import 'moment-precise-range-plugin'
import truncate from 'lodash/truncate'

// import Config from '../Config.json'
import { getStone } from '../myModules/reactStone.js'
import spJSON from '../data/sp.json'
import languagesEn from '../data/languagesEn.json'
import levelsEn from '../data/levelsEn.json'
// console.log('spJSON = ', spJSON);



export const escapeHtml = (text) => {
  // 1. exchange to bbcode
  let map = {
    '<b>': '[b]',
    '</b>': '[/b]',
    '<i>': '[i]',
    '</i>': '[/i]',
    '<br>': '[br]',
    '<h1>': '[h1]',
    '</h1>': '[/h1]',
    '<h2>': '[h2]',
    '</h2>': '[/h2]',
    '<h3>': '[h3]',
    '</h3>': '[/h3]',
    '<h4>': '[h4]',
    '</h4>': '[/h4]',
    '<h5>': '[h5]',
    '</h5>': '[/h5]',
    '<h6>': '[h6]',
    '</h6>': '[/h6]',
    '<p>': '[p]',
    '</p>': '[/p]',
    '<ul>': '[ul]',
    '</ul>': '[/ul]',
    '<li>': '[li]',
    '</li>': '[/li]',

  }
  text = text.replace(/(<br>|<i>|<\/i>|<b>|<\/b>|<h1>|<\/h1>|<h2>|<\/h2>|<h3>|<\/h3>|<h4>|<\/h4>|<h5>|<\/h5>|<h6>|<\/h6>|<p>|<\/p>|<ul>|<\/ul>|<li>|<\/li>)/g, function(m) { return map[m] })
  text = text.replace(/<(a[^<>]+?)>/g, '[$1]')
  text = text.replace(/<\/a>/g, '[/a]')
  console.log('text = ', text)

  // 2. remove
  map = {
    '<': '&lt;',
    '>': '&gt;'
  }
  
  text = text.replace(/[<>]/g, function(m) { return map[m]; })

  // 3. exchange back bbcode
  map = {
    '[b]': '<b>',
    '[/b]': '</b>',
    '[i]': '<i>',
    '[/i]': '</i>',
    '[br]': '<br>',
    '[h1]': '<h1>',
    '[/h1]': '</h1>',
    '[h2]': '<h2>',
    '[/h2]': '</h2>',
    '[h3]': '<h3>',
    '[/h3]': '</h3>',
    '[h4]': '<h4>',
    '[/h4]': '</h4>',
    '[h5]': '<h5>',
    '[/h5]': '</h5>',
    '[h6]': '<h6>',
    '[/h6]': '</h6>',
    '[p]': '<p>',
    '[/p]': '</p>',
    '[ul]': '<ul>',
    '[/ul]': '</ul>',
    '[li]': '<li>',
    '[/li]': '</li>'
  }
  text = text.replace(/(\[br\]|\[i\]|\[\/i\]|\[b\]|\[\/b\]|\[h1\]|\[\/h1\]|\[h2\]|\[\/h2\]|\[h3\]|\[\/h3\]|\[h4\]|\[\/h4\]|\[h5\]|\[\/h5\]|\[h6\]|\[\/h6\]|\[p\]|\[\/p\]|\[ul\]|\[\/ul\]|\[li\]|\[\/li\])/g, function(m) { return map[m] })
  text = text.replace(/\[(a[^<>]+?)\]/g, '<$1 rel="nofollow">')
  text = text.replace(/\[\/a\]/g, '</a>')





  return text
}



// ###############################
// for the projects/id

export const getAvatar = avatar => {
  if (avatar) {
    return `${avatar}`
  } else {
    return `/img/default-avatar.png`
  }
}



// insert ReCaptcha
export const insertReCaptcha = (locale) => {
  if (!window.isLoadingReCaptcha) {
    window.isLoadingReCaptcha = true;

    // include recaptcha files
    window.onloadCallback = () => {
      window.grecaptchaLoaded = true
      // console.log('2window.grecaptcha = ', window.grecaptcha)
    }

    // 1. include a recaptcha API
    let head = document.getElementsByTagName('head')[0]
    let script = document.createElement('script')
    script.setAttribute('data-main', 'js/main');
    script.src = `https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=${getReCaptchaLocale(locale)}`
    script.type = "text/javascript"
    script.async = true 
    script.defer = true
    script.id = 'my-script'

    // console.log('script = ', script)
    head.appendChild(script)
  }
}



// delete reCaptcha
export const deleteReCaptcha = () => {
  document.querySelectorAll('head script[src*=recaptcha]').forEach(e => e.parentNode.removeChild(e))
}



// export const getDurationOnTheSite = registrationDate => {
//   const date = moment(registrationDate)
//   const now = moment()
//   const diff = moment.preciseDiff(now, date, true)
//   // console.log('diff = ', diff);
//   let durationOnTheSite = '';
//   if (diff.years) {
//     diff.years > 1 ? durationOnTheSite += `${diff.years} years ` : durationOnTheSite += `${diff.years} year `
//   }
//   if (diff.months) {
//     diff.months > 1 ? durationOnTheSite += `${diff.months} months ` : durationOnTheSite += `${diff.months} month `
//   }
//   if (diff.days) {
//     diff.days > 1 ? durationOnTheSite += `${diff.days} days ` : durationOnTheSite += `${diff.days} day `
//   }
//   if (diff.minutes) {
//     diff.minutes > 1 ? durationOnTheSite += `${diff.minutes} minutes ` : durationOnTheSite += `${diff.minutes} minute `
//   }

//   return durationOnTheSite
// }



export const getBudget = budget => {

  return budget ? budget.toLocaleString().replace(/,/g, ' ') : ''
}



export const getSpecializationsOfProject = (specializations, locale) => {
  // console.log('specializations = ', specializations)
  // console.log('locale = ', locale)
  // return 'unknown'
  // return specializations
  try {
    let _ = getStone().gettext
    let sp1 = specializations.sp1
    let sp2 = specializations.sp2
    let sp3 = specializations.sp3
    let result = []

    // console.log('sp1 = ', sp1, ' sp2 = ', sp2, ' sp3 = ', sp3)
    // console.log(`spJSON['sp'][sp1.sp].title = `, spJSON['sp'][sp1.sp].title)
    // console.log(`spJSON['subSp'][sp1.subSp].title = `, spJSON['subSp'][sp1.subSp].title)
    if (sp1) {
      result.push(
        <div key="sp1">
          {spJSON.sp[sp1.sp] && spJSON.sp[sp1.sp].title &&
            <Link
              to={`/${locale}/freelancers/?specialization=${sp1.sp}`}
            >
              {_(spJSON.sp[sp1.sp].title)}
            </Link> 
          }
          /
          {spJSON.subSp[sp1.subSp] && spJSON.subSp[sp1.subSp].title &&
            <Link
              to={`/${locale}/freelancers/?specialization=${sp1.subSp}`}
            >
              {_(spJSON.subSp[sp1.subSp].title)}
            </Link>
          }
        </div>
      )
    }

    if (sp2) {
      result.push(
        <div key="sp2">
          {spJSON.sp[sp2.sp] && spJSON.sp[sp2.sp].title &&
            <Link
              to={`/${locale}/freelancers/?specialization=${sp2.sp}`}
            >
              {_(spJSON.sp[sp2.sp].title)}
            </Link> 
          }
          /
          {spJSON.subSp[sp2.subSp] && spJSON.subSp[sp2.subSp].title &&
            <Link
              to={`/${locale}/freelancers/?specialization=${sp2.subSp}`}
            >
              {_(spJSON.subSp[sp2.subSp].title)}
            </Link>
          }
        </div>
      )
    }

    if (sp3) {
      result.push(
        <div key="sp3">
          {spJSON.sp[sp3.sp] && spJSON.sp[sp3.sp].title &&
            <Link
              to={`/${locale}/freelancers/?specialization=${sp3.sp}`}
            >
              {spJSON.sp[sp3.sp].title}
            </Link> 
          }
          /
          {spJSON.subSp[sp3.subSp] && spJSON.subSp[sp3.subSp].title &&
            <Link
              to={`/${locale}/freelancers/?specialization=${sp3.subSp}`}
            >
              {_(spJSON.subSp[sp3.subSp].title)}
            </Link>
          }
        </div>
      )
    }
    // console.log('result = ', result)
    return result
  }
  catch(err) {
    console.log('err = ', err)
    return " "
  }
}



export const getDescription = (description) => {
  return description.replace(/\n/g, '<br/>')
}

export const getTagText = (text) => {
  if (text) {
    text = escapeHtml(text)
    text = text.replace(/(?<!<\/h1>|<\/h2>|<\/h3>|<\/h4>|<\/h5>|<\/h6>|<ul>|<\/ul>|<\/li>)\n/g, '<br />')
    text = text.replace(/((?<!href=")(?:https:\/\/|http:\/\/)[^\s].+)/g, '<a href="$1" rel="nofollow">$1</a>')
  }

  return text
}



export const getDescriptionForProjectsTape = (description) => {
  description = truncate(description, { length: 100 })
  return description
}



// convert Offers's status to text
export const convertStatusToTextOnOffersPage = (status) => {
  if (status === 'unresolved') {
    return 'Your candidacy is pending'
  } else if (status === 'candidate') {
    return 'You are a candidate'
  } else if (status === 'rewarded') {
    return 'You are a performer'
  } else if (status === 'spam') {
    return 'Marked as spam'
  } else if (status === 'refused') {
    return 'Customer has canceled the offer.'
  } else if (status === 'refusedThemselves') {
    return 'You have canceled the offer'
  } else {
    return undefined
  }
}



export const getLanguages = (languages, locale) => {
  if (!languages) {
    return ''
  }

  let result = []
  let _ = getStone().gettext

  Object.entries(languages).forEach(([key, val], i) => {
    let comma = ''
    if (i !== 0) {
      comma = ', '
    }
    result.push(
      <span key={i}>
        {comma}{_(languagesEn[val.language])} ({_(levelsEn[val.level])})
      </span>
    )
  })
  return result
  // return JSON.stringify(languages)
}



// output all the specializations for the user on the settings pages
export const getAllUserSpecializations = (specializations, locale) => {
  if (!specializations) {
    return ''
  }

  let { sp1, sp2, sp3 } = specializations
  // console.log('sp1 = ', sp1)
  // console.log('sp2 = ', sp2)
  // console.log('sp3 = ', sp3)

  let result = []
  let _ = getStone().gettext


  if (sp1) {
    result.push(
      <div key="sp1" className="specialization">
        <div>
          <Link to={`/${locale}/freelancers/?specialization=${sp1.sp}`}>{_(spJSON.sp[sp1.sp].title)}</Link>
        </div>
        <ul>
          <li
            style={{ display: !(sp1.subSp && sp1.subSp[0]) ? 'none' : null }}
          >
            <span>{sp1.subSp && spJSON.subSp[sp1.subSp[0]] && _(spJSON.subSp[sp1.subSp[0]].title)}</span>
          </li>
          <li
            style={{ display: !(sp1.subSp && sp1.subSp[1]) ? 'none' : null }}
          >
            <span>{sp1.subSp && spJSON.subSp[sp1.subSp[1]] &&  _(spJSON.subSp[sp1.subSp[1]].title)}</span>
          </li>
          <li
            style={{ display: !(sp1.subSp && sp1.subSp[2]) ? 'none' : null }}
          >
            <span>{sp1.subSp && spJSON.subSp[sp1.subSp[2]] &&  _(spJSON.subSp[sp1.subSp[2]].title)}</span>
          </li>
        </ul>
      </div>
    )
  }

  if (sp2) {
    result.push(
      <div key="sp2" className="specialization">
        <div>
          <span>{_(spJSON.sp[sp2.sp].title)}</span>
        </div>
        <ul>
          <li
            style={{ display: !(sp2.subSp && sp2.subSp[0]) ? 'none' : null }}
          >
            <span>{sp2.subSp && spJSON.subSp[sp2.subSp[0]] && _(spJSON.subSp[sp2.subSp[0]].title)}</span>
          </li>
          <li
            style={{ display: !(sp2.subSp && sp2.subSp[1]) ? 'none' : null }}
          >
            <span>{sp2.subSp && spJSON.subSp[sp2.subSp[1]] &&  _(spJSON.subSp[sp2.subSp[1]].title)}</span>
          </li>
          <li
            style={{ display: !(sp2.subSp && sp2.subSp[2]) ? 'none' : null }}
          >
            <span>{sp2.subSp && spJSON.subSp[sp2.subSp[2]] &&  _(spJSON.subSp[sp2.subSp[2]].title)}</span>
          </li>
        </ul>
      </div>
    )
  }
  
  if (sp3) {
    result.push(
      <div key="sp3" className="specialization">
        <div>
          <span>{_(spJSON.sp[sp3.sp].title)}</span>
        </div>
        <ul>
          <li
            style={{ display: !(sp3.subSp && sp3.subSp[0]) ? 'none' : null }}
          >
            <span>{sp3.subSp && spJSON.subSp[sp3.subSp[0]] && _(spJSON.subSp[sp3.subSp[0]].title)}</span>
          </li>
          <li
            style={{ display: !(sp3.subSp && sp3.subSp[1]) ? 'none' : null }}
          >
            <span>{sp3.subSp && spJSON.subSp[sp3.subSp[1]] && _(spJSON.subSp[sp3.subSp[1]].title)}</span>
          </li>
          <li
            style={{ display: !(sp3.subSp && sp3.subSp[2]) ? 'none' : null }}
          >
            <span>{sp3.subSp && spJSON.subSp[sp3.subSp[2]] &&  _(spJSON.subSp[sp3.subSp[2]].title)}</span>
          </li>
        </ul>
      </div>
    )
  }

  return result
}



// get one user specialization
export const getOneUserSpecialization = (specializations) => {
  // console.log('specializations = ', specializations)
  try {
    if (specializations && specializations.sp1 && specializations.sp1.subSp && specializations.sp1.subSp.length && spJSON.subSp[specializations.sp1.subSp[0]].title) {
      return spJSON.subSp[specializations.sp1.subSp[0]].title
    } else if (specializations && specializations.sp2 && specializations.sp2.subSp && specializations.sp2.subSp.length && spJSON.subSp[specializations.sp2.subSp[0]].title) {
      return spJSON.subSp[specializations.sp2.subSp[0]].title
    } else if (specializations && specializations.sp3 && specializations.sp3.subSp && specializations.sp3.subSp.length && spJSON.subSp[specializations.sp3.subSp[0]].title) {
      return spJSON.subSp[specializations.sp3.subSp[0]].title
    }

    return " "
  } catch (err) {

    return " "
  }
}



// get one specialization
export const getOneSpecialization = (specializations) => {
  // console.log('getOneSpecialization = ', specializations)
  return specializations

  // let { sp1 } = JSON.parse(specializations)
  // let result = []

  // if (sp1) {
  //   result.push(
  //     <a
  //       key="sp1"
  //       style={{ display: !(sp1.subSp && sp1.subSp[0]) ? 'none' : null }}
  //     > {sp1.subSp && spJSON.subSp[sp1.subSp[0]]}</a>
  //   )
  // }

  // return result
}



// get google captcha locale
export const getReCaptchaLocale = (locale) => {
  if (locale === 'ua') {
    return 'uk'
  } else if (locale === 'in') {
    return 'hi'
  } else if (locale === 'cn') {
    return 'zh-CN'
  } else {
    return locale
  }
}



// ====================
// the /myprojects page

export const getOffers = (number, locale) => {
  let numberStr = ''
  if (locale === 'en') {
    if (number === 1) {
      numberStr = 'offer'
    } else {
      numberStr = 'offers'
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ru') {
    if (number === 0) {
      numberStr = `предложений`
    } else if (number === 1) {
      numberStr = `предложение`
    } else if (number >= 2 && number <= 4) {
      numberStr = `предложения`
    } else if (number >= 5 && number <= 20) {
      numberStr = `предложений`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `предложений`
      } else if (ten === 1) {
        numberStr = `предложение`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `предложения`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `предложений`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ua') {
    if (number === 0) {
      numberStr = `пропозицій`
    } else if (number === 1) {
      numberStr = `пропозиція`
    } else if (number >= 2 && number <= 4) {
      numberStr = `пропозиції`
    } else if (number >= 5 && number <= 20) {
      numberStr = `пропозицій`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `пропозицій`
      } else if (ten === 1) {
        numberStr = `пропозиція`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `пропозиції`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `пропозицій`
      }
    }

    return `${number} ${numberStr}`
  } else {
    return " ddd"
  }
}



export const getNewOffers = (number, locale) => {
  let numberStr = ''
  if (locale === 'en') {
    if (number === 1) {
      numberStr = 'new offer'
    } else {
      numberStr = 'new offers'
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ru') {
    if (number === 0) {
      numberStr = `новых предложений`
    } else if (number === 1) {
      numberStr = `новое предложение`
    } else if (number >= 2 && number <= 4) {
      numberStr = `новых предложения`
    } else if (number >= 5 && number <= 20) {
      numberStr = `новых предложений`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `новых предложений`
      } else if (ten === 1) {
        numberStr = `новое предложение`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `новых предложения`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `новых предложений`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ua') {
    if (number === 0) {
      numberStr = `нових пропозицій`
    } else if (number === 1) {
      numberStr = `нова пропозиція`
    } else if (number >= 2 && number <= 4) {
      numberStr = `нові пропозиції`
    } else if (number >= 5 && number <= 20) {
      numberStr = `нових пропозицій`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `нових пропозицій`
      } else if (ten === 1) {
        numberStr = `нова пропозиція`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `нові пропозиції`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `нових пропозицій`
      }
    }

    return `${number} ${numberStr}`
  } else {
    return " aa"
  }
}



export const getMessages = (number, locale) => {
  let numberStr = ''
  if (locale === 'en') {
    if (number === 1) {
      numberStr = 'message'
    } else {
      numberStr = 'messages'
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ru') {
    if (number === 0) {
      numberStr = `сообщений`
    } else if (number === 1) {
      numberStr = `сообщение`
    } else if (number >= 2 && number <= 4) {
      numberStr = `сообщения`
    } else if (number >= 5 && number <= 20) {
      numberStr = `сообщений`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `сообщений`
      } else if (ten === 1) {
        numberStr = `сообщение`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `сообщения`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `сообщений`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ua') {
    if (number === 0) {
      numberStr = `повідомлень`
    } else if (number === 1) {
      numberStr = `повідомлення`
    } else if (number >= 2 && number <= 4) {
      numberStr = `повідомлення`
    } else if (number >= 5 && number <= 20) {
      numberStr = `повідомлень`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `повідомлень`
      } else if (ten === 1) {
        numberStr = `повідомлення`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `повідомлення`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `повідомлень`
      }
    }

    return `${number} ${numberStr}`
  } else {
    return " "
  }
}



export const getNewMessages = (number, locale) => {
  let numberStr = ''
  if (locale === 'en') {
    if (number === 1) {
      numberStr = 'new message'
    } else {
      numberStr = 'new messages'
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ru') {
    if (number === 0) {
      numberStr = `новых сообщений`
    } else if (number === 1) {
      numberStr = `новое сообщение`
    } else if (number >= 2 && number <= 4) {
      numberStr = `новых сообщения`
    } else if (number >= 5 && number <= 20) {
      numberStr = `новых сообщений`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `новых сообщений`
      } else if (ten === 1) {
        numberStr = `новое сообщение`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `новых сообщения`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `новых сообщений`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ua') {
    if (number === 0) {
      numberStr = `нових повідомлень`
    } else if (number === 1) {
      numberStr = `новe повідомлення`
    } else if (number >= 2 && number <= 4) {
      numberStr = `нових повідомлення`
    } else if (number >= 5 && number <= 20) {
      numberStr = `нових повідомлень`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `нових повідомлень`
      } else if (ten === 1) {
        numberStr = `новe повідомлення`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `нових повідомлення`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `нових повідомлень`
      }
    }

    return `${number} ${numberStr}`
  } else {
    return " "
  }
}

// the /myprojects page
// ====================



// =============
// the Header.js

export const getTooltipPrivateMessages = (number, locale) => {
  let numberStr = ''
  if (locale === 'en') {
    if (number === 1) {
      numberStr = 'new private message'
    } else {
      numberStr = 'new private messages'
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ru') {
    if (number === 0) {
      numberStr = `новых личных сообщений`
    } else if (number === 1) {
      numberStr = `новое личное сообщение`
    } else if (number >= 2 && number <= 4) {
      numberStr = `новых личных сообщения`
    } else if (number >= 5 && number <= 20) {
      numberStr = `новых личных сообщений`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `новых личных сообщений`
      } else if (ten === 1) {
        numberStr = `новое личное сообщение`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `новых личных сообщения`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `новых личных сообщений`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ua') {
    if (number === 0) {
      numberStr = `нових приватних повідомлень`
    } else if (number === 1) {
      numberStr = `новe приватне повідомлення`
    } else if (number >= 2 && number <= 4) {
      numberStr = `нових приватних повідомлення`
    } else if (number >= 5 && number <= 20) {
      numberStr = `нових приватних повідомлень`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `нових приватних повідомлень`
      } else if (ten === 1) {
        numberStr = `новe приватне повідомлення`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `нових приватних повідомлення`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `нових приватних повідомлень`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'in') {
    numberStr = `नए निजी संदेश`
    return `${number} ${numberStr}`
  } else if (locale === 'cn') {
    numberStr = `条新的私人留言`
    return `${number} ${numberStr}`
  } else if (locale === 'es') {
    numberStr = `nuevos mensajes privados`
    return `${number} ${numberStr}`
  } else if (locale === 'es') {
    numberStr = `nuevos mensajes privados`
    return `${number} ${numberStr}`
  } else if (locale === 'de') {
    numberStr = `neue private Nachricht`
    return `${number} ${numberStr}`
  } else if (locale === 'pl') {
    numberStr = `nowe prywatne wiadomości`
    return `${number} ${numberStr}`
  } else if (locale === 'fr') {
    numberStr = `nouveau message privé`
    return `${number} ${numberStr}`
  } else {
    return " "
  }
}

export const getTooltipNewProjectMessages = (number, locale) => {
  let numberStr = ''
  if (locale === 'en') {
    if (number === 1) {
      numberStr = 'new message'
    } else {
      numberStr = 'new messages'
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ru') {
    if (number === 0) {
      numberStr = `новых сообщений`
    } else if (number === 1) {
      numberStr = `новое сообщение`
    } else if (number >= 2 && number <= 4) {
      numberStr = `новых сообщения`
    } else if (number >= 5 && number <= 20) {
      numberStr = `новых сообщений`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `новых сообщений`
      } else if (ten === 1) {
        numberStr = `новое сообщение`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `новых сообщения`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `новых сообщений`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ua') {
    if (number === 0) {
      numberStr = `нових повідомлень`
    } else if (number === 1) {
      numberStr = `новe повідомлення`
    } else if (number >= 2 && number <= 4) {
      numberStr = `нових повідомлення`
    } else if (number >= 5 && number <= 20) {
      numberStr = `нових повідомлень`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `нових повідомлень`
      } else if (ten === 1) {
        numberStr = `новe повідомлення`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `нових повідомлення`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `нових повідомлень`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'in') {
    numberStr = `नए संदेश`
    return `${number} ${numberStr}`
  } else if (locale === 'cn') {
    numberStr = `条新讯息`
    return `${number} ${numberStr}`
  } else if (locale === 'es') {
    numberStr = `mensajes nuevos`
    return `${number} ${numberStr}`
  } else if (locale === 'de') {
    numberStr = `neue Nachrichten`
    return `${number} ${numberStr}`
  } else if (locale === 'pl') {
    numberStr = `nowe wiadomości`
    return `${number} ${numberStr}`
  } else if (locale === 'fr') {
    numberStr = `nouveaux messages`
    return `${number} ${numberStr}`
  } else {
    return " "
  }
}

export const getTooltipNewProjectOffers = (number, locale) => {
  let numberStr = ''
  if (locale === 'en') {
    if (number === 1) {
      numberStr = 'new offer'
    } else {
      numberStr = 'new offers'
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ru') {
    if (number === 0) {
      numberStr = `новых предложений`
    } else if (number === 1) {
      numberStr = `новое предложение`
    } else if (number >= 2 && number <= 4) {
      numberStr = `новых предложения`
    } else if (number >= 5 && number <= 20) {
      numberStr = `новых предложений`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `новых предложений`
      } else if (ten === 1) {
        numberStr = `новое предложение`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `новых предложения`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `новых предложений`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ua') {
    if (number === 0) {
      numberStr = `нових пропозицій`
    } else if (number === 1) {
      numberStr = `нова пропозиція`
    } else if (number >= 2 && number <= 4) {
      numberStr = `нові пропозиції`
    } else if (number >= 5 && number <= 20) {
      numberStr = `нових пропозицій`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `нових пропозицій`
      } else if (ten === 1) {
        numberStr = `нова пропозиція`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `нові пропозиції`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `нових пропозицій`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'in') {
    numberStr = `नई पेशकश`
    return `${number} ${numberStr}`
  } else if (locale === 'cn') {
    numberStr = `个新报价`
    return `${number} ${numberStr}`
  } else if (locale === 'es') {
    numberStr = `nueva oferta`
    return `${number} ${numberStr}`
  } else if (locale === 'de') {
    numberStr = `neues Angebot`
    return `${number} ${numberStr}`
  } else if (locale === 'pl') {
    numberStr = `nowa oferta`
    return `${number} ${numberStr}`
  } else if (locale === 'fr') {
    numberStr = `nouvelle offre`
    return `${number} ${numberStr}`
  } else {
    return " "
  }
}

export const getTooltipNewOfferMessages = (number, locale) => {
  let numberStr = ''
  if (locale === 'en') {
    if (number === 1) {
      numberStr = 'new message'
    } else {
      numberStr = 'new messages'
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ru') {
    if (number === 0) {
      numberStr = `новых сообщений`
    } else if (number === 1) {
      numberStr = `новое сообщение`
    } else if (number >= 2 && number <= 4) {
      numberStr = `новых сообщения`
    } else if (number >= 5 && number <= 20) {
      numberStr = `новых сообщений`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `новых сообщений`
      } else if (ten === 1) {
        numberStr = `новое сообщение`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `новых сообщения`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `новых сообщений`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ua') {
    if (number === 0) {
      numberStr = `нових повідомлень`
    } else if (number === 1) {
      numberStr = `новe повідомлення`
    } else if (number >= 2 && number <= 4) {
      numberStr = `нових повідомлення`
    } else if (number >= 5 && number <= 20) {
      numberStr = `нових повідомлень`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `нових повідомлень`
      } else if (ten === 1) {
        numberStr = `новe повідомлення`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `нових повідомлення`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `нових повідомлень`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'in') {
    numberStr = `नई पेशकश`
    return `${number} ${numberStr}`
  } else if (locale === 'cn') {
    numberStr = `个新报价`
    return `${number} ${numberStr}`
  } else if (locale === 'es') {
    numberStr = `nueva oferta`
    return `${number} ${numberStr}`
  } else if (locale === 'de') {
    numberStr = `neues Angebot`
    return `${number} ${numberStr}`
  } else if (locale === 'pl') {
    numberStr = `nowa oferta`
    return `${number} ${numberStr}`
  } else if (locale === 'fr') {
    numberStr = `nouvelle offre`
    return `${number} ${numberStr}`
  } else {
    return " "
  }
}

export const getTooltipNewOrderMessages = (number, locale) => {
  let numberStr = ''
  if (locale === 'en') {
    if (number === 1) {
      numberStr = 'new message'
    } else {
      numberStr = 'new messages'
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ru') {
    if (number === 0) {
      numberStr = `новых сообщений`
    } else if (number === 1) {
      numberStr = `новое сообщение`
    } else if (number >= 2 && number <= 4) {
      numberStr = `новых сообщения`
    } else if (number >= 5 && number <= 20) {
      numberStr = `новых сообщений`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `новых сообщений`
      } else if (ten === 1) {
        numberStr = `новое сообщение`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `новых сообщения`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `новых сообщений`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ua') {
    if (number === 0) {
      numberStr = `нових повідомлень`
    } else if (number === 1) {
      numberStr = `новe повідомлення`
    } else if (number >= 2 && number <= 4) {
      numberStr = `нових повідомлення`
    } else if (number >= 5 && number <= 20) {
      numberStr = `нових повідомлень`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `нових повідомлень`
      } else if (ten === 1) {
        numberStr = `новe повідомлення`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `нових повідомлення`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `нових повідомлень`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'in') {
    numberStr = `नई पेशकश`
    return `${number} ${numberStr}`
  } else if (locale === 'cn') {
    numberStr = `个新报价`
    return `${number} ${numberStr}`
  } else if (locale === 'es') {
    numberStr = `nueva oferta`
    return `${number} ${numberStr}`
  } else if (locale === 'de') {
    numberStr = `neues Angebot`
    return `${number} ${numberStr}`
  } else if (locale === 'pl') {
    numberStr = `nowa oferta`
    return `${number} ${numberStr}`
  } else if (locale === 'fr') {
    numberStr = `nouvelle offre`
    return `${number} ${numberStr}`
  } else {
    return " "
  }
}

export const getTooltipNewOrdersOffers = (number, locale) => {
  let numberStr = ''
  if (locale === 'en') {
    if (number === 1) {
      numberStr = 'new order'
    } else {
      numberStr = 'new orders'
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ru') {
    if (number === 0) {
      numberStr = `новых заказов`
    } else if (number === 1) {
      numberStr = `новое заказ`
    } else if (number >= 2 && number <= 4) {
      numberStr = `новых заказа`
    } else if (number >= 5 && number <= 20) {
      numberStr = `новых заказов`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `новых заказов`
      } else if (ten === 1) {
        numberStr = `новое заказ`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `новых заказа`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `новых заказов`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ua') {
    if (number === 0) {
      numberStr = `нових замовлення пропозицій`
    } else if (number === 1) {
      numberStr = `нова замовлення пропозиція`
    } else if (number >= 2 && number <= 4) {
      numberStr = `нові замовлення пропозиції`
    } else if (number >= 5 && number <= 20) {
      numberStr = `нових замовлення пропозицій`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `нових замовлення пропозицій`
      } else if (ten === 1) {
        numberStr = `нова замовлення пропозиція`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `нові замовлення пропозиції`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `нових замовлення пропозицій`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'in') {
    numberStr = `नए आदेश`
    return `${number} ${numberStr}`
  } else if (locale === 'cn') {
    numberStr = `个新订单`
    return `${number} ${numberStr}`
  } else if (locale === 'es') {
    numberStr = `nuevos pedidos`
    return `${number} ${numberStr}`
  } else if (locale === 'de') {
    numberStr = `neue Bestellungen`
    return `${number} ${numberStr}`
  } else if (locale === 'pl') {
    numberStr = `nowe zamówienia`
    return `${number} ${numberStr}`
  } else if (locale === 'fr') {
    numberStr = `nouvelles commandes`
    return `${number} ${numberStr}`
  } else {
    return " "
  }
}

// Header.js
// =========


export const getDays = (number, locale) => {
  let numberStr = ''
  if (locale === 'en') {
    if (number === 1) {
      numberStr = 'day'
    } else {
      numberStr = 'days'
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ru') {
    if (number === 0) {
      numberStr = `дней`
    } else if (number === 1) {
      numberStr = `день`
    } else if (number >= 2 && number <= 4) {
      numberStr = `дня`
    } else if (number >= 5 && number <= 20) {
      numberStr = `дней`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `дней`
      } else if (ten === 1) {
        numberStr = `день`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `дня`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `дней`
      }
    }

    return `${number} ${numberStr}`
  } else if (locale === 'ua') {
    if (number === 0) {
      numberStr = `днів`
    } else if (number === 1) {
      numberStr = `день`
    } else if (number >= 2 && number <= 4) {
      numberStr = `дні`
    } else if (number >= 5 && number <= 20) {
      numberStr = `днів`
    } else if (number > 20) {
      let ten = Math.round(number % 10)
      if (ten === 0) {
        numberStr = `днів`
      } else if (ten === 1) {
        numberStr = `день`
      } else if (ten >= 2 && ten <= 4) {
        numberStr = `дні`
      } else if (ten >= 5 && ten <= 10) {
        numberStr = `днів`
      }
    }
    
    return `${number} ${numberStr}`
  } else {
    return " "
  }
}


// =======
// getters

export const getPreciseDiff = (str, locale) => {
  // console.log('str = ', str)
  // console.log('locale = ', locale)
  if (locale === 'en') {
    ;
  } else if (locale === 'ru') {
    str = str.replace(/hours/g, "часы")
    str = str.replace(/minutes/g, "минут")
    str = str.replace(/seconds/g, "секунд")
  } else if (locale === 'ua') {
    str = str.replace(/hours/g, "години")
    str = str.replace(/minutes/g, "хвилин")
    str = str.replace(/seconds/g, "секунд")
  } else if (locale === 'in') {
    str = str.replace(/hours/g, "घंटे")
    str = str.replace(/minutes/g, "मिनट")
    str = str.replace(/seconds/g, "सेकंड")
  } else if (locale === 'cn') {
    str = str.replace(/hours/g, "小时")
    str = str.replace(/minutes/g, "分钟")
    str = str.replace(/seconds/g, "秒")
  } else if (locale === 'es') {
    str = str.replace(/hours/g, "horas")
    str = str.replace(/minutes/g, "minutos")
    str = str.replace(/seconds/g, "segundos")
  } else if (locale === 'de') {
    str = str.replace(/hours/g, "Std")
    str = str.replace(/minutes/g, "Protokoll")
    str = str.replace(/seconds/g, "Sekunden")
  } else if (locale === 'pl') {
    str = str.replace(/hours/g, "godziny")
    str = str.replace(/minutes/g, "minuty")
    str = str.replace(/seconds/g, "sekundy")
  } else if (locale === 'fr') {
    str = str.replace(/hours/g, "heures")
    str = str.replace(/minutes/g, "minutes")
    str = str.replace(/seconds/g, "seconds")
  }

  return str
}



export const getAges = (d, locale) => {
  const date = moment(d)
  const now = moment()
  const diff = moment.preciseDiff(now, date, true)

  // console.log('diff = ', diff)

  return diff.years
}



export const getFreelancerPercentReview = (percentOfPositiveReviews, numberOfReviews, locale) => {
  
  // let percentOfPositiveReviewsStr = ''
  let numberOfReviewsStr = ''
  if (locale === 'en') {
    if (numberOfReviews === 1) {
      numberOfReviewsStr = 'review'
    } else {
      numberOfReviewsStr = 'reviews'
    }
    return (
      <span>
        <span className="block1"><span className="percent">{percentOfPositiveReviews}%</span> positive reviews.</span> <span className="block2">Total <span className="reviews">{numberOfReviews}</span> {numberOfReviewsStr}.</span>
      </span>
    )
  } else if (locale === 'ru') {
    if (numberOfReviews === 0) {
      numberOfReviewsStr = `отзывов`
    } else if (numberOfReviews === 1) {
      numberOfReviewsStr = `отзыв`
    } else if (numberOfReviews >= 2 && numberOfReviews <= 4) {
      numberOfReviewsStr = `отзыва`
    } else if (numberOfReviews >= 5 && numberOfReviews <= 20) {
      numberOfReviewsStr = `отзывов`
    } else if (numberOfReviews > 20) {
      let ten = Math.round(numberOfReviews % 10)
      if (ten === 0) {
        numberOfReviewsStr = `отзывов`
      } else if (ten === 1) {
        numberOfReviewsStr = `отзыв`
      } else if (ten >= 2 && ten <= 4) {
        numberOfReviewsStr = `отзыва`
      } else if (ten >= 5 && ten <= 10) {
        numberOfReviewsStr = `отзывов`
      }
    }
    return (
      <span>
        <span className="block1"><span className="percent">{percentOfPositiveReviews}%</span> положительных отзывов.</span> <span className="block2">Всего <span className="reviews">{numberOfReviews}</span> {numberOfReviewsStr}.</span>
      </span>
    )
  } else if (locale === 'ua') {
    if (numberOfReviews === 0) {
      numberOfReviewsStr = `відгуків`
    } else if (numberOfReviews === 1) {
      numberOfReviewsStr = `відгук`
    } else if (numberOfReviews >= 2 && numberOfReviews <= 4) {
      numberOfReviewsStr = `відгука`
    } else if (numberOfReviews >= 5 && numberOfReviews <= 20) {
      numberOfReviewsStr = `відгуків`
    } else if (numberOfReviews > 20) {
      let ten = Math.round(numberOfReviews % 10)
      if (ten === 0) {
        numberOfReviewsStr = `відгуків`
      } else if (ten === 1) {
        numberOfReviewsStr = `відгук`
      } else if (ten >= 2 && ten <= 4) {
        numberOfReviewsStr = `відгука`
      } else if (ten >= 5 && ten <= 10) {
        numberOfReviewsStr = `відгуків`
      }
    }
    return (
      <span>
        <span className="block1"><span className="percent">{percentOfPositiveReviews}%</span> позитивних відгуків.</span> <span className="block2">Всього <span className="reviews">{numberOfReviews}</span> {numberOfReviewsStr}.</span>
      </span>
    )
  }

  // an instance
  return (<span>
    <span className="block1"><span className="percent">{percentOfPositiveReviews}%</span> положительных отзывов.</span> <span className="block2">Всего <span className="reviews">{numberOfReviews}</span> отзывов.</span>
  </span>)
}



export const getReviewsTitle = (reviews, locale) => {
  // return "something"
  if (locale === 'en') {
    if (reviews === 1) {
      return `${reviews} all time review`
    } else {
      return `${reviews} all time reviews`
    }
  } else if (locale === 'ru') {
    if (reviews === 0) {
      reviews = `${reviews} отзывов`
    } else if (reviews === 1) {
      reviews = `${reviews} отзыв`
    } else if (reviews >= 2 && reviews <= 4) {
      reviews = `${reviews} отзыва`
    } else if (reviews >= 5 && reviews <= 20) {
      reviews = `${reviews} отзывов`
    } else if (reviews > 20) {
      let ten = Math.round(reviews % 10)
      if (ten === 0) {
        reviews = `${reviews} отзывов`
      } else if (ten === 1) {
        reviews = `${reviews} отзыв`
      } else if (ten >= 2 && ten <= 4) {
        reviews = `${reviews} отзыва`
      } else if (ten >= 5 && ten <= 10) {
        reviews = `${reviews} отзывов`
      }
    }
    return `${reviews} за всё время`
  } else if (locale === 'ua') {
    if (reviews === 0) {
      reviews = `${reviews} відгуків`
    } else if (reviews === 1) {
      reviews = `${reviews} відгук`
    } else if (reviews >= 2 && reviews <= 4) {
      reviews = `${reviews} відгука`
    } else if (reviews >= 5 && reviews <= 20) {
      reviews = `${reviews} відгуків`
    } else if (reviews > 20) {
      let ten = Math.round(reviews % 10)
      if (ten === 0) {
        reviews = `${reviews} відгуків`
      } else if (ten === 1) {
        reviews = `${reviews} відгук`
      } else if (ten >= 2 && ten <= 4) {
        reviews = `${reviews} відгука`
      } else if (ten >= 5 && ten <= 10) {
        reviews = `${reviews} відгуків`
      }
    }
    return `${reviews} за весь час`
  }
}



export const getExperienceTitle = (years, locale) => {
  if (locale === 'en') {
    if (years === 1) {
      return `Experience 1 year`
    } else {
      return `Experience ${years} years`
    }
  } else if (locale === 'ru') {
    if (years === 0) {
      years = `${years} лет`
    } else if (years === 1) {
      years = `${years} год`
    } else if (years >= 2 && years <= 4) {
      years = `${years} года`
    } else if (years >= 5 && years <= 20) {
      years = `${years} лет`
    } else if (years > 20) {
      let ten = Math.round(years % 10)
      if (ten === 0) {
        years = `${years} лет`
      } else if (ten === 1) {
        years = `${years} год`
      } else if (ten >= 2 && ten <= 4) {
        years = `${years} года`
      } else if (ten >= 5 && ten <= 10) {
        years = `${years} лет`
      }
    }

    return `Опыт работы ${years}`
  } else if (locale === 'ua') {
    if (years === 0) {
      years = `${years} років`
    } else if (years === 1) {
      years = `${years} рік`
    } else if (years >= 2 && years <= 4) {
      years = `${years} роки`
    } else if (years >= 5 && years <= 20) {
      years = `${years} років`
    } else if (years > 20) {
      let ten = Math.round(years % 10)
      if (ten === 0) {
        years = `${years} років`
      } else if (ten === 1) {
        years = `${years} рік`
      } else if (ten >= 2 && ten <= 4) {
        years = `${years} роки`
      } else if (ten >= 5 && ten <= 10) {
        years = `${years} років`
      }
    }

    return `Досвід роботи ${years}`
  }
}



export const getAgreement = (locale) => {
  let phrase = ''
  if (locale === 'en') {
    phrase = <div className="agreement">By clicking the “Register” button, I agree with <Link to={`/${locale}/siterules/`}>the site rules</Link>, I agree to the processing of personal data.</div>
  } else if (locale === 'ru') {
    phrase = <div className="agreement">Нажимая кнопку «Зарегистрироваться», я соглашаюсь c <Link to={`/${locale}/siterules/`}>правилами сайта</Link>, я согласен на обработку персональных данных.</div>
  } else if (locale === 'ua') {
    phrase = <div className="agreement">Натиснувши кнопку «Зареєструватися», я погоджуюся з <Link to={`/${locale}/siterules/`}>правилами сайту</Link>, я погоджуюся на обробку персональних даних.</div>
  }
  return phrase
}



export const getDurationOnTheSite = (d, locale) => {
  if (locale === 'en') {
    const date = moment(d)
    const now = moment()
    const diff = moment.preciseDiff(now, date, true)
    if (diff.years) {
      let years = diff.years
      if (years === 1) {
        years = `${years} year`
      } else {
        years = `${years} years`
      }

      let months = diff.months
      if (months === 1) {
        months = `${months} month`
      } else {
        months = `${months} months`
      }

      return `${years} ${months}`
    } else if (diff.months) {
      let months = diff.months
      if (months === 1) {
        months = `${months} month`
      } else {
        months = `${months} months`
      }

      let days = diff.days
      if (days === 1) {
        days = `${days} day`
      } else {
        days = `${days} days`
      }

      return `${months} ${days}`
    } else if (diff.days) {
      let days = diff.days
      if (days === 1) {
        days = `${days} day`
      } else {
        days = `${days} days`
      }

      let hours = diff.hours
      if (hours === 1) {
        hours = `${hours} hour`
      } else {
        hours = `${hours} hours`
      }

      return `${days} ${hours}`
    } else if (diff.hours) {
      let hours = diff.hours
      if (hours === 1) {
        hours = `${hours} hour`
      } else {
        hours = `${hours} hours`
      }

      let minutes = diff.minutes
      if (minutes === 1) {
        minutes = `${minutes} minute`
      } else {
        minutes = `${minutes} minutes`
      }

      return `${hours} ${minutes}`
    } else if (diff.minutes) {
      let minutes = diff.minutes
      if (minutes === 1) {
        minutes = `${minutes} minute`
      } else {
        minutes = `${minutes} minutes`
      }

      let seconds = diff.seconds
      if (seconds === 1) {
        seconds = `${seconds} second`
      } else {
        seconds = `${seconds} seconds`
      }

      return `${minutes} ${seconds}`
    } else if (diff.seconds) {
      let seconds = diff.seconds
      if (seconds === 1) {
        seconds = `${seconds} second`
      } else {
        seconds = `${seconds} seconds`
      }

      return `${diff.seconds}`
    }
  } else if (locale === 'ru') {
    const date = moment(d)
    const now = moment()
    const diff = moment.preciseDiff(now, date, true)
    if (diff.years) {
      // 6. years
      let years = diff.years
      if (years === 0) {
        years = `${years} лет`
      } else if (years === 1) {
        years = `${years} год`
      } else if (years >= 2 && years <= 4) {
        years = `${years} года`
      } else if (years >= 5 && years <= 20) {
        years = `${years} лет`
      } else if (years > 20) {
        let ten = Math.round(years % 10)
        if (ten === 0) {
          years = `${years} лет`
        } else if (ten === 1) {
          years = `${years} год`
        } else if (ten >= 2 && ten <= 4) {
          years = `${years} года`
        } else if (ten >= 5 && ten <= 10) {
          years = `${years} лет`
        }
      }

      // 5. months
      let months = diff.months
      if (months === 0) {
        months = `${months} месяцeв`
      } else if (months === 1) {
        months = `${months} месяц`
      } else if (months >= 2 && months <= 4) {
        months = `${months} месяца`
      } else if (months >= 5 && months <= 20) {
        months = `${months} месяцeв`
      } else if (months > 20) {
        let ten = Math.round(months % 10)
        if (ten === 0) {
          months = `${months} месяцeв`
        } else if (ten === 1) {
          months = `${months} месяц`
        } else if (ten >= 2 && ten <= 4) {
          months = `${months} месяца`
        } else if (ten >= 5 && ten <= 10) {
          months = `${months} месяцeв`
        }
      }

      return `${years} ${months}`
    } else if (diff.months) {
      // 5. months
      let months = diff.months
      if (months === 0) {
        months = `${months} месяцeв`
      } else if (months === 1) {
        months = `${months} месяц`
      } else if (months >= 2 && months <= 4) {
        months = `${months} месяца`
      } else if (months >= 5 && months <= 20) {
        months = `${months} месяцeв`
      } else if (months > 20) {
        let ten = Math.round(months % 10)
        if (ten === 0) {
          months = `${months} месяцeв`
        } else if (ten === 1) {
          months = `${months} месяц`
        } else if (ten >= 2 && ten <= 4) {
          months = `${months} месяца`
        } else if (ten >= 5 && ten <= 10) {
          months = `${months} месяцeв`
        }
      }

      // 4. days
      let days = diff.days
      if (days === 0) {
        days = `${days} дней`
      } else if (days === 1) {
        days = `${days} день`
      } else if (days >= 2 && days <= 4) {
        days = `${days} дня`
      } else if (days >= 5 && days <= 20) {
        days = `${days} дней`
      } else if (days > 20) {
        let ten = Math.round(days % 10)
        if (ten === 0) {
          days = `${days} дней`
        } else if (ten === 1) {
          days = `${days} день`
        } else if (ten >= 2 && ten <= 4) {
          days = `${days} дня`
        } else if (ten >= 5 && ten <= 10) {
          days = `${days} дней`
        }
      }

      return `${months} ${days}`
    } else if (diff.days) {
      // 4. days
      let days = diff.days
      if (days === 0) {
        days = `${days} дней`
      } else if (days === 1) {
        days = `${days} день`
      } else if (days >= 2 && days <= 4) {
        days = `${days} дня`
      } else if (days >= 5 && days <= 20) {
        days = `${days} дней`
      } else if (days > 20) {
        let ten = Math.round(days % 10)
        if (ten === 0) {
          days = `${days} дней`
        } else if (ten === 1) {
          days = `${days} день`
        } else if (ten >= 2 && ten <= 4) {
          days = `${days} дня`
        } else if (ten >= 5 && ten <= 10) {
          days = `${days} дней`
        }
      }

      // 3. hours
      let hours = diff.hours
      if (hours === 0) {
        hours = `${hours} часов`
      } else if (hours === 1) {
        hours = `${hours} час`
      } else if (hours >= 2 && hours <= 4) {
        hours = `${hours} часа`
      } else if (hours >= 5 && hours <= 20) {
        hours = `${hours} часов`
      } else if (hours > 20) {
        let ten = Math.round(hours % 10)
        if (ten === 0) {
          hours = `${hours} часов`
        } else if (ten === 1) {
          hours = `${hours} час`
        } else if (ten >= 2 && ten <= 4) {
          hours = `${hours} часа`
        } else if (ten >= 5 && ten <= 10) {
          hours = `${hours} часов`
        }
      }

      return `${days} ${hours}`
    } else if (diff.hours) {
      // 3. hours
      let hours = diff.hours
      if (hours === 0) {
        hours = `${hours} часов`
      } else if (hours === 1) {
        hours = `${hours} час`
      } else if (hours >= 2 && hours <= 4) {
        hours = `${hours} часа`
      } else if (hours >= 5 && hours <= 20) {
        hours = `${hours} часов`
      } else if (hours > 20) {
        let ten = Math.round(hours % 10)
        if (ten === 0) {
          hours = `${hours} часов`
        } else if (ten === 1) {
          hours = `${hours} час`
        } else if (ten >= 2 && ten <= 4) {
          hours = `${hours} часа`
        } else if (ten >= 5 && ten <= 10) {
          hours = `${hours} часов`
        }
      }

      // 2. minutes
      let minutes = diff.minutes
      if (minutes === 0) {
        minutes = `${minutes} минут`
      } else if (minutes === 1) {
        minutes = `${minutes} минуту`
      } else if (minutes >= 2 && minutes <= 4) {
        minutes = `${minutes} минуты`
      } else if (minutes >= 5 && minutes <= 20) {
        minutes = `${minutes} минут`
      } else if (minutes > 20) {
        let ten = Math.round(minutes % 10)
        if (ten === 0) {
          minutes = `${minutes} минут`
        } else if (ten === 1) {
          minutes = `${minutes} минуту`
        } else if (ten >= 2 && ten <= 4) {
          minutes = `${minutes} минуты`
        } else if (ten >= 5 && ten <= 10) {
          minutes = `${minutes} минут`
        }
      }
      
      return `${hours} ${minutes}`
    } else if (diff.minutes) {
      // 2. minutes
      let minutes = diff.minutes
      if (minutes === 0) {
        minutes = `${minutes} минут`
      } else if (minutes === 1) {
        minutes = `${minutes} минуту`
      } else if (minutes >= 2 && minutes <= 4) {
        minutes = `${minutes} минуты`
      } else if (minutes >= 5 && minutes <= 20) {
        minutes = `${minutes} минут`
      } else if (minutes > 20) {
        let ten = Math.round(minutes % 10)
        if (ten === 0) {
          minutes = `${minutes} минут`
        } else if (ten === 1) {
          minutes = `${minutes} минуту`
        } else if (ten >= 2 && ten <= 4) {
          minutes = `${minutes} минуты`
        } else if (ten >= 5 && ten <= 10) {
          minutes = `${minutes} минут`
        }
      }

      // 1. seconds
      let seconds = diff.seconds
      if (seconds === 0) {
        seconds = `${seconds} секунд`
      } else if (seconds === 1) {
        seconds = `${seconds} секунду`
      } else if (seconds >= 2 && seconds <= 4) {
        seconds = `${seconds} секунды`
      } else if (seconds >= 5 && seconds <= 20) {
        seconds = `${seconds} секунд`
      } else if (seconds > 20) {
        let ten = Math.round(seconds % 10)
        if (ten === 0) {
          seconds = `${seconds} секунд`
        } else if (ten === 1) {
          seconds = `${seconds} секунду`
        } else if (ten >= 2 && ten <= 4) {
          seconds = `${seconds} секунды`
        } else if (ten >= 5 && ten <= 10) {
          seconds = `${seconds} секунд`
        }
      }
      
      return `${minutes} ${seconds}`
    } else if (diff.seconds) {
      // 1. seconds
      let seconds = diff.seconds
      if (seconds === 0) {
        seconds = `${seconds} секунд`
      } else if (seconds === 1) {
        seconds = `${seconds} секунду`
      } else if (seconds >= 2 && seconds <= 4) {
        seconds = `${seconds} секунды`
      } else if (seconds >= 5 && seconds <= 20) {
        seconds = `${seconds} секунд`
      } else if (seconds > 20) {
        let ten = Math.round(seconds % 10)
        if (ten === 0) {
          seconds = `${seconds} секунд`
        } else if (ten === 1) {
          seconds = `${seconds} секунду`
        } else if (ten >= 2 && ten <= 4) {
          seconds = `${seconds} секунды`
        } else if (ten >= 5 && ten <= 10) {
          seconds = `${seconds} секунд`
        }
      }
      
      return `${seconds}`
    }
  } else if (locale === 'ua') {
    const date = moment(d)
    const now = moment()
    const diff = moment.preciseDiff(now, date, true)
    if (diff.years) {
      // 6. years
      let years = diff.years
      if (years === 0) {
        years = `${years} років`
      } else if (years === 1) {
        years = `${years} рік`
      } else if (years >= 2 && years <= 4) {
        years = `${years} роки`
      } else if (years >= 5 && years <= 20) {
        years = `${years} років`
      } else if (years > 20) {
        let ten = Math.round(years % 10)
        if (ten === 0) {
          years = `${years} років`
        } else if (ten === 1) {
          years = `${years} рік`
        } else if (ten >= 2 && ten <= 4) {
          years = `${years} роки`
        } else if (ten >= 5 && ten <= 10) {
          years = `${years} років`
        }
      }

      // 5. months
      let months = diff.months
      if (months === 0) {
        months = `${months} місяців`
      } else if (months === 1) {
        months = `${months} місяць`
      } else if (months >= 2 && months <= 4) {
        months = `${months} місяці`
      } else if (months >= 5 && months <= 20) {
        months = `${months} місяців`
      } else if (months > 20) {
        let ten = Math.round(months % 10)
        if (ten === 0) {
          months = `${months} місяців`
        } else if (ten === 1) {
          months = `${months} місяць`
        } else if (ten >= 2 && ten <= 4) {
          months = `${months} місяці`
        } else if (ten >= 5 && ten <= 10) {
          months = `${months} місяців`
        }
      }

      return `${years} ${months}`
    } else if (diff.months) {
      // 5. months
      let months = diff.months
      if (months === 0) {
        months = `${months} місяців`
      } else if (months === 1) {
        months = `${months} місяць`
      } else if (months >= 2 && months <= 4) {
        months = `${months} місяці`
      } else if (months >= 5 && months <= 20) {
        months = `${months} місяців`
      } else if (months > 20) {
        let ten = Math.round(months % 10)
        if (ten === 0) {
          months = `${months} місяців`
        } else if (ten === 1) {
          months = `${months} місяць`
        } else if (ten >= 2 && ten <= 4) {
          months = `${months} місяці`
        } else if (ten >= 5 && ten <= 10) {
          months = `${months} місяців`
        }
      }

      // 4. days
      let days = diff.days
      if (days === 0) {
        days = `${days} днів`
      } else if (days === 1) {
        days = `${days} день`
      } else if (days >= 2 && days <= 4) {
        days = `${days} дні`
      } else if (days >= 5 && days <= 20) {
        days = `${days} днів`
      } else if (days > 20) {
        let ten = Math.round(days % 10)
        if (ten === 0) {
          days = `${days} днів`
        } else if (ten === 1) {
          days = `${days} день`
        } else if (ten >= 2 && ten <= 4) {
          days = `${days} дні`
        } else if (ten >= 5 && ten <= 10) {
          days = `${days} днів`
        }
      }

      return `${months} ${days}`
    } else if (diff.days) {
      // 4. days
      let days = diff.days
      if (days === 0) {
        days = `${days} днів`
      } else if (days === 1) {
        days = `${days} день`
      } else if (days >= 2 && days <= 4) {
        days = `${days} дні`
      } else if (days >= 5 && days <= 20) {
        days = `${days} днів`
      } else if (days > 20) {
        let ten = Math.round(days % 10)
        if (ten === 0) {
          days = `${days} днів`
        } else if (ten === 1) {
          days = `${days} день`
        } else if (ten >= 2 && ten <= 4) {
          days = `${days} дні`
        } else if (ten >= 5 && ten <= 10) {
          days = `${days} днів`
        }
      }

      // 3. hours
      let hours = diff.hours
      if (hours === 0) {
        hours = `${hours} годин`
      } else if (hours === 1) {
        hours = `${hours} годину`
      } else if (hours >= 2 && hours <= 4) {
        hours = `${hours} години`
      } else if (hours >= 5 && hours <= 20) {
        hours = `${hours} годин`
      } else if (hours > 20) {
        let ten = Math.round(hours % 10)
        if (ten === 0) {
          hours = `${hours} годин`
        } else if (ten === 1) {
          hours = `${hours} годину`
        } else if (ten >= 2 && ten <= 4) {
          hours = `${hours} години`
        } else if (ten >= 5 && ten <= 10) {
          hours = `${hours} годин`
        }
      }

      return `${days} ${hours}`
    } else if (diff.hours) {
      // 3. hours
      let hours = diff.hours
      if (hours === 0) {
        hours = `${hours} годин`
      } else if (hours === 1) {
        hours = `${hours} годину`
      } else if (hours >= 2 && hours <= 4) {
        hours = `${hours} години`
      } else if (hours >= 5 && hours <= 20) {
        hours = `${hours} годин`
      } else if (hours > 20) {
        let ten = Math.round(hours % 10)
        if (ten === 0) {
          hours = `${hours} годин`
        } else if (ten === 1) {
          hours = `${hours} годину`
        } else if (ten >= 2 && ten <= 4) {
          hours = `${hours} години`
        } else if (ten >= 5 && ten <= 10) {
          hours = `${hours} годин`
        }
      }

      // 2. minutes
      let minutes = diff.minutes
      if (minutes === 0) {
        minutes = `${minutes} хвилин`
      } else if (minutes === 1) {
        minutes = `${minutes} хвилину`
      } else if (minutes >= 2 && minutes <= 4) {
        minutes = `${minutes} хвилини`
      } else if (minutes >= 5 && minutes <= 20) {
        minutes = `${minutes} хвилин`
      } else if (minutes > 20) {
        let ten = Math.round(minutes % 10)
        if (ten === 0) {
          minutes = `${minutes} хвилин`
        } else if (ten === 1) {
          minutes = `${minutes} хвилину`
        } else if (ten >= 2 && ten <= 4) {
          minutes = `${minutes} хвилини`
        } else if (ten >= 5 && ten <= 10) {
          minutes = `${minutes} хвилин`
        }
      }
      
      return `${hours} ${minutes}`
    } else if (diff.minutes) {
      // 2. minutes
      let minutes = diff.minutes
      if (minutes === 0) {
        minutes = `${minutes} хвилин`
      } else if (minutes === 1) {
        minutes = `${minutes} хвилину`
      } else if (minutes >= 2 && minutes <= 4) {
        minutes = `${minutes} хвилини`
      } else if (minutes >= 5 && minutes <= 20) {
        minutes = `${minutes} хвилин`
      } else if (minutes > 20) {
        let ten = Math.round(minutes % 10)
        if (ten === 0) {
          minutes = `${minutes} хвилин`
        } else if (ten === 1) {
          minutes = `${minutes} хвилину`
        } else if (ten >= 2 && ten <= 4) {
          minutes = `${minutes} хвилини`
        } else if (ten >= 5 && ten <= 10) {
          minutes = `${minutes} хвилин`
        }
      }

      // 1. seconds
      let seconds = diff.seconds
      if (seconds === 0) {
        seconds = `${seconds} секунд`
      } else if (seconds === 1) {
        seconds = `${seconds} секунду`
      } else if (seconds >= 2 && seconds <= 4) {
        seconds = `${seconds} секунды`
      } else if (seconds >= 5 && seconds <= 20) {
        seconds = `${seconds} секунд`
      } else if (seconds > 20) {
        let ten = Math.round(seconds % 10)
        if (ten === 0) {
          seconds = `${seconds} секунд`
        } else if (ten === 1) {
          seconds = `${seconds} секунду`
        } else if (ten >= 2 && ten <= 4) {
          seconds = `${seconds} секунды`
        } else if (ten >= 5 && ten <= 10) {
          seconds = `${seconds} секунд`
        }
      }
      
      return `${minutes} ${seconds}`
    } else if (diff.seconds) {
      // 1. seconds
      let seconds = diff.seconds
      if (seconds === 0) {
        seconds = `${seconds} секунд`
      } else if (seconds === 1) {
        seconds = `${seconds} секунду`
      } else if (seconds >= 2 && seconds <= 4) {
        seconds = `${seconds} секунды`
      } else if (seconds >= 5 && seconds <= 20) {
        seconds = `${seconds} секунд`
      } else if (seconds > 20) {
        let ten = Math.round(seconds % 10)
        if (ten === 0) {
          seconds = `${seconds} секунд`
        } else if (ten === 1) {
          seconds = `${seconds} секунду`
        } else if (ten >= 2 && ten <= 4) {
          seconds = `${seconds} секунды`
        } else if (ten >= 5 && ten <= 10) {
          seconds = `${seconds} секунд`
        }
      }
      
      return `${seconds}`
    }
  } else if (locale === 'in') {
    const date = moment(d)
    const now = moment()
    const diff = moment.preciseDiff(now, date, true)

    if (diff.years) {
      // 6. years
      let years = diff.years
      years = `${years} साल`
      let months = diff.months
      months = `${months} महीने`
      return `${years} ${months}`
    } else if (diff.months) {
      // 5. months
      let months = diff.months
      months = `${months} महीने`
      let days = diff.days
      days = `${days} दिन`
      return `${months} ${days}`
    } else if (diff.days) {
      // 4. days
      let days = diff.days
      days = `${days} दिन`
      let hours = diff.hours
      hours = `${hours} घंटे`
      return `${days} ${hours}`
    } else if (diff.hours) {
      // 3. hours
      let hours = diff.hours
      hours = `${hours} घंटे`
      let minutes = diff.minutes
      minutes = `${minutes} मिनट`
      return `${hours} ${minutes}`
    } else if (diff.minutes) {
      // 2. minutes
      let minutes = diff.minutes
      minutes = `${minutes} मिनट`
      let seconds = diff.seconds
      seconds = `${seconds} सेकंड`
      return `${minutes} ${seconds}`
    } else if (diff.seconds) {
      // 1. seconds
      let seconds = diff.seconds
      seconds = `${seconds} सेकंड`
      return `${seconds}`
    }

  } else if (locale === 'cn') {
    const date = moment(d)
    const now = moment()
    const diff = moment.preciseDiff(now, date, true)

    if (diff.years) {
      // 6. years
      let years = diff.years
      years = `${years} 年`
      let months = diff.months
      months = `${months} 个月`
      return `${years} ${months}`
    } else if (diff.months) {
      // 5. months
      let months = diff.months
      months = `${months} 个月`
      let days = diff.days
      days = `${days} 天`
      return `${months} ${days}`
    } else if (diff.days) {
      // 4. days
      let days = diff.days
      days = `${days} 天`
      let hours = diff.hours
      hours = `${hours} 小时`
      return `${days} ${hours}`
    } else if (diff.hours) {
      // 3. hours
      let hours = diff.hours
      hours = `${hours} 小时`
      let minutes = diff.minutes
      minutes = `${minutes} 分钟`
      return `${hours} ${minutes}`
    } else if (diff.minutes) {
      // 2. minutes
      let minutes = diff.minutes
      minutes = `${minutes} 分钟`
      let seconds = diff.seconds
      seconds = `${seconds} 秒`
      return `${minutes} ${seconds}`
    } else if (diff.seconds) {
      // 1. seconds
      let seconds = diff.seconds
      seconds = `${seconds} 秒`
      return `${seconds}`
    }
  } else if (locale === 'es') {
    const date = moment(d)
    const now = moment()
    const diff = moment.preciseDiff(now, date, true)

    // console.log('date = ', date)
    // console.log('now = ', now)
    // console.log('diff = ', diff)

    if (diff.years) {
      // 6. years
      let years = diff.years
      years = `${years} años`
      let months = diff.months
      months = `${months} meses`
      return `${years} ${months}`
    } else if (diff.months) {
      // 5. months
      let months = diff.months
      months = `${months} meses`
      let days = diff.days
      days = `${days} días`
      return `${months} ${days}`
    } else if (diff.days) {
      // 4. days
      let days = diff.days
      days = `${days} días`
      let hours = diff.hours
      hours = `${hours} horas`
      return `${days} ${hours}`
    } else if (diff.hours) {
      // 3. hours
      let hours = diff.hours
      hours = `${hours} horas`
      let minutes = diff.minutes
      minutes = `${minutes} minutos`
      return `${hours} ${minutes}`
    } else if (diff.minutes) {
      // 2. minutes
      let minutes = diff.minutes
      minutes = `${minutes} minutos`
      let seconds = diff.seconds
      seconds = `${seconds} segundos`
      return `${minutes} ${seconds}`
    } else if (diff.seconds) {
      // 1. seconds
      let seconds = diff.seconds
      seconds = `${seconds} segundos`
      return `${seconds}`
    }
  } else if (locale === 'de') {
    const date = moment(d)
    const now = moment()
    const diff = moment.preciseDiff(now, date, true)

    if (diff.years) {
      // 6. years
      let years = diff.years
      years = `${years} Jahre`
      let months = diff.months
      months = `${months} Monate`
      return `${years} ${months}`
    } else if (diff.months) {
      // 5. months
      let months = diff.months
      months = `${months} Monate`
      let days = diff.days
      days = `${days} Tage`
      return `${months} ${days}`
    } else if (diff.days) {
      // 4. days
      let days = diff.days
      days = `${days} Tage`
      let hours = diff.hours
      hours = `${hours} Stunden`
      return `${days} ${hours}`
    } else if (diff.hours) {
      // 3. hours
      let hours = diff.hours
      hours = `${hours} Stunden`
      let minutes = diff.minutes
      minutes = `${minutes} Minuten`
      return `${hours} ${minutes}`
    } else if (diff.minutes) {
      // 2. minutes
      let minutes = diff.minutes
      minutes = `${minutes} Minuten`
      let seconds = diff.seconds
      seconds = `${seconds} Sekunden`
      return `${minutes} ${seconds}`
    } else if (diff.seconds) {
      // 1. seconds
      let seconds = diff.seconds
      seconds = `${seconds} Sekunden`
      return `${seconds}`
    }
  } else if (locale === 'pl') {
    const date = moment(d)
    const now = moment()
    const diff = moment.preciseDiff(now, date, true)

    if (diff.years) {
      // 6. years
      let years = diff.years
      years = `${years} lat`
      let months = diff.months
      months = `${months} miesięcy`
      return `${years} ${months}`
    } else if (diff.months) {
      // 5. months
      let months = diff.months
      months = `${months} miesięcy`
      let days = diff.days
      days = `${days} dni`
      return `${months} ${days}`
    } else if (diff.days) {
      // 4. days
      let days = diff.days
      days = `${days} dni`
      let hours = diff.hours
      hours = `${hours} godzin`
      return `${days} ${hours}`
    } else if (diff.hours) {
      // 3. hours
      let hours = diff.hours
      hours = `${hours} godzin`
      let minutes = diff.minutes
      minutes = `${minutes} minut`
      return `${hours} ${minutes}`
    } else if (diff.minutes) {
      // 2. minutes
      let minutes = diff.minutes
      minutes = `${minutes} minut`
      let seconds = diff.seconds
      seconds = `${seconds} sekund`
      return `${minutes} ${seconds}`
    } else if (diff.seconds) {
      // 1. seconds
      let seconds = diff.seconds
      seconds = `${seconds} sekund`
      return `${seconds}`
    }
  } else if (locale === 'fr') {
    const date = moment(d)
    const now = moment()
    const diff = moment.preciseDiff(now, date, true)

    // console.log('date = ', date)
    // console.log('now = ', now)
    // console.log('diff = ', diff)

    if (diff.years) {
      // 6. years
      let years = diff.years
      years = `${years} ans`
      let months = diff.months
      months = `${months} mois`
      return `${years} ${months}`
    } else if (diff.months) {
      // 5. months
      let months = diff.months
      months = `${months} mois`
      let days = diff.days
      days = `${days} jours`
      return `${months} ${days}`
    } else if (diff.days) {
      // 4. days
      let days = diff.days
      days = `${days} jours`
      let hours = diff.hours
      hours = `${hours} heures`
      return `${days} ${hours}`
    } else if (diff.hours) {
      // 3. hours
      let hours = diff.hours
      hours = `${hours} heures`
      let minutes = diff.minutes
      minutes = `${minutes} minutes`
      return `${hours} ${minutes}`
    } else if (diff.minutes) {
      // 2. minutes
      let minutes = diff.minutes
      minutes = `${minutes} minutes`
      let seconds = diff.seconds
      seconds = `${seconds} secondes`
      return `${minutes} ${seconds}`
    } else if (diff.seconds) {
      // 1. seconds
      let seconds = diff.seconds
      seconds = `${seconds} secondes`
      return `${seconds}`
    }
  }

  console.log('locale = ', locale)
}

// export const getLoggedOnTheSite = (d, locale) => {
//   return "here I am"
// }

export const getLastVisitDuration = (str, locale) => {
  // if (locale === 'en') {
  //   ;
  // } else if (locale === 'ru') {
  //   str = str.replace(/months/g, "месяцев")
  //   str = str.replace(/days/g, "дней")
  //   str = str.replace(/hours/g, "часы")
  //   str = str.replace(/minutes/g, "минут")
  //   str = str.replace(/seconds/g, "секунд")
  // } else if (locale === 'ua') {
  //   str = str.replace(/months/g, "мiсяцiв")
  //   str = str.replace(/days/g, "днiв")
  //   str = str.replace(/hours/g, "години")
  //   str = str.replace(/minutes/g, "хвилин")
  //   str = str.replace(/seconds/g, "секунд")
  // }

  return str
}



export const getTinymceLocale = (locale) => {
  if (locale === 'ua') {
    return 'uk'
  }

  return locale
}



export const getThumbnail = (url) => {
  if (!url) {
    url = `/img/no-image-icon200x200.png`
  }

  return url
}



export const removeHTMLtags = (str) => {
  if (!str) {
    return ''
  }
  
  str = str.replace(/<[^<>]*?>/g, '')

  return str
}



// export const removeScriptTags = (str) => {
//   str = str.replace(/<[^<>]*?script[^<>]*?>/g, '')
//   return str
// }





// getters
// =======