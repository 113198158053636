import React from 'react'
import ReactTooltip from 'react-tooltip'
import { withRouter, NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import * as a from '../redux/actions'
import * as f from '../utils/functions.js'
import _isEqual from 'lodash/isEqual'
import { withStone } from '../myModules/reactStone.js'

class Header extends React.Component {
  constructor(props) {
    super(props)

    let MMOOCounters = {
      numberOfNewPrivateMessages: 0,
      numberOfNewMyProjectsOffers: 0,
      numberOfNewMyProjectsOffersMessages: 0,
      numberOfNewOffersMessages: 0,
      numberOfNewOrders: 0,
      numberOfNewOrdersMessages: 0
    }

    // console.log('this.props.MMOOCounters = ', this.props.MMOOCounters)
    if (this.props.MMOOCounters) {
      MMOOCounters = { ...this.props.MMOOCounters }
    }

    this.state = {
      toggle: false,
      toggleFlags: false,
      languages: [ 'ru', 'en', 'ua', 'in', 'cn','es', 'de', 'pl', 'fr' ],
      showProfileMenu: false,
      MMOOCounters
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }
  // componentWillMount() {
  //   document.addEventListener('mousedown', this.handleClick, false)
  // }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('componentDidUpdate')
    if (!_isEqual(prevProps.MMOOCounters, this.props.MMOOCounters)) {
      this.setState({
        MMOOCounters: { ...this.props.MMOOCounters }
      })
    }
  }

  handleClick = e => {
    if (!this.node.contains(e.target)) {
      // || this.node2.contains(e.target)
      // || this.node3.contains(e.target)) {
      if (this.state.toggleFlags) {
        this.setState({ toggleFlags: false })
      }
    }

    
    if (this.node2 && !this.node2.contains(e.target)) {
      if (this.state.showProfileMenu) {
        this.setState({ showProfileMenu: false })
      }
    }
    
  }

  onToggle = (e) => {
    this.setState({ toggle: !this.state.toggle })
  }

  onToggleFlags = (e) => {
    this.setState({ toggleFlags: !this.state.toggleFlags })
    e && e.preventDefault()
  }

  showProfileMenu = e => {
    this.setState({ showProfileMenu: !this.state.showProfileMenu })
  }

  onClickLanguage = locale => e => {
    // console.log('onClickLanguage ')
    e.preventDefault()
    window.location.href = `/${locale}${this.props.location.pathname.substring(3) ? this.props.location.pathname.substring(3) : '/'}${this.props.location.search}`
    // return
    // let url = `/${locale}${this.props.location.pathname.substring(3)}${this.props.location.search}`
    // // console.log('url = ', url)
    // this.props.history.push(url)
  }

  onClickLogout = e => {
    e.preventDefault()
    // console.log('onClickLogout1')
    this.isFetching = true
    // this.setState(this.state)
    fetch(`/api/v1/logout/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(r => {
        this.isFetching = false
        if (!r.ok) {
          return Promise.reject()
        } else {
          return r.json()
        }
      })
      .then(json => {
        // console.log('json = ', json)
        if(json.error) {
          return this.setState({ ...json })
        } else {
          // console.log('here we are')
          // return
          // let { dispatch } = this.props
          // dispatch(a.removeIdLoginToken())

          this.props.history.push(`/${this.props.globals.locale}/logout/`)

          // let func = () => {
          //   console.log('interval = ', interval)
          //   clearInterval(interval)
          //   let { dispatch } = this.props
          //   dispatch(a.removeIdLoginToken())
          // }
          // let interval = setInterval(func, 0)
          

          // window.sessionStorage.removeItem('formHomePageData')
          // window.sessionStorage.removeItem('formPortfolioPageData')
          // window.sessionStorage.removeItem('formFreelancersPageData')
          // window.sessionStorage.removeItem('formProjectsPageData')

          // let interval = setInterval(() => {
          //   this.props.history.push(`/${this.props.globals.locale}/login/`)
          //   console.log('interval')
          //   clearInterval(interval)
          // },0)
        }
      })
      .catch(error => {
        console.log(console.log('error = ', error))
        return this.setState({ error })
      })
  }

  render() {
    const _ = this.props.Stone.gettext
    // let { /*locale,*/ dispatch, login, avatar } = this.props
    let { locale, avatar, login } = this.props.globals

    // console.log('this.state = ', this.state)
    // console.log('this.props = ', this.props)

    // console.log('avatar = ', avatar);
    // avatar = avatar ? Config.serverUrl + '/uploads' + avatar : '/img/default-avatar.png'
    avatar = f.getAvatar(avatar)
    // console.log('AVATAR = ', avatar);
    const { toggle, toggleFlags } = this.state
    const active = toggle ? 'active' : ''
    const activeFlags = toggleFlags ? 'active' : ''
    // console.log('activeFlags = ', activeFlags)

    let languages = [...this.state.languages]
    const index = languages.indexOf(locale)
    if(~index) {
      languages.splice(index, 1);
    }
    const localeFl = locale !== 'en' ? locale : 'gb'
    // console.log('localeFl = ', localeFl)

    let { MMOOCounters } = this.state

    return (
      <div className="header">
        <div>
          <nav className={`navigation ${ login ? "logged" : "unlogged" }`}>
            <span
              onClick={this.onToggle}
              ref={input => {this.navbarToggle = input}} 
              className="navbar-toggle" id="js-navbar-toggle">
              <i className="fas fa-bars"></i>
            </span>
            <div className="logo">
              <NavLink to={`/`} exact className="logo">
                <img src="/img/logo.png" alt="" />
              </NavLink>
            </div>
            <ul className={ `${active} left` }>
              <li className="projects"><NavLink to={`/${locale}/projects/`} exact>{_("Projects")}</NavLink></li>
              <li className="freelancers"><NavLink to={`/${locale}/freelancers/`} exact>{_("Freelancers")}</NavLink></li>
              <li className="portfolio"><NavLink to={`/${locale}/portfolio/`} exact>{_("Portfolio")}</NavLink></li>
              <li className="navbar-toggle1">
                <span
                  onClick={this.onToggle}
                  ref={input => {this.navbarToggle = input}} 
                  className="dnavbar-toggle1" id="js-navbar-toggle1">
                  <i className="fas fa-bars"></i>
                </span>
              </li>
            </ul>
            <ul className={ `${active} right` } >
              
              <li className="publishProject">
                <div className="buttonPublish">
                  <Link
                    to={`/${this.props.globals.locale}/publish/`}
                  >
                    {_("Publish project")}
                  </Link>
                </div>
              </li>

              {login &&
                <li className="messages">
                  <NavLink
                    className="messages"
                    to={`/${locale}/messages/`}
                    exact
                  >
                    {_("Messages")}
                    {MMOOCounters.numberOfNewPrivateMessages > 0 &&
                      <span 
                        className="numberOfNewPrivateMessages"
                        data-for="numberOfNewPrivateMessages"
                        data-tip={f.getTooltipPrivateMessages(MMOOCounters.numberOfNewPrivateMessages, this.props.globals.locale)}
                      >
                        {MMOOCounters.numberOfNewPrivateMessages}
                        <ReactTooltip
                          id="numberOfNewPrivateMessages"
                          place="bottom"
                          type="info"
                          effect="solid"
                        />
                      </span>
                    }
                  </NavLink>
                </li>
              }
              {login &&
                <li className="myprojects">
                  <NavLink
                    className="myprojects"
                    to={`/${locale}/myprojects/`}
                    exact
                  >
                    {_("My projects")}
                    {MMOOCounters.numberOfNewMyProjectsOffers > 0 &&
                      <span 
                        className="numberOfNewMyProjectsOffers"
                        data-for="numberOfNewMyProjectsOffers"
                        data-tip={f.getTooltipNewProjectOffers(MMOOCounters.numberOfNewMyProjectsOffers, this.props.globals.locale)}
                      >
                        {MMOOCounters.numberOfNewMyProjectsOffers}
                        <ReactTooltip
                          id="numberOfNewMyProjectsOffers"
                          place="bottom"
                          type="info"
                          effect="solid"
                        />
                      </span>
                    }
                    {MMOOCounters.numberOfNewMyProjectsOffersMessages > 0 &&
                      <span 
                        className="numberOfNewMyProjectsOffersMessages"
                        data-for="numberOfNewMyProjectsOffersMessages"
                        data-tip={f.getTooltipNewProjectMessages(MMOOCounters.numberOfNewMyProjectsOffersMessages, this.props.globals.locale)}
                      >
                        {MMOOCounters.numberOfNewMyProjectsOffersMessages}
                        <ReactTooltip
                          id="numberOfNewMyProjectsOffersMessages"
                          place="bottom"
                          type="info"
                          effect="solid"
                        />
                      </span>
                    }
                  </NavLink>
                </li>
              }
              {login && 
                <li className="offers">
                  <NavLink
                    className="offers"
                    to={`/${locale}/offers/`}
                    exact
                  >
                    {_("Offers")}
                    {MMOOCounters.numberOfNewOffersMessages > 0 &&
                      <span 
                        className="numberOfNewOffersMessages"
                        data-for="numberOfNewOffersMessages"
                        data-tip={f.getTooltipNewOfferMessages(MMOOCounters.numberOfNewOffersMessages, this.props.globals.locale)}
                      >
                        {MMOOCounters.numberOfNewOffersMessages}
                        <ReactTooltip
                          id="numberOfNewOffersMessages"
                          place="bottom"
                          type="info"
                          effect="solid"
                        />
                      </span>
                    }
                  </NavLink>
                </li>
              }
              {login &&
                <li className="orders">
                  <NavLink
                    className="orders"
                    to={`/${locale}/orders/`}
                    exact
                  >
                    {_("Orders")}
                    {MMOOCounters.numberOfNewOrders > 0 &&
                      <span 
                        className="numberOfNewOrders"
                        data-for="numberOfNewOrders"
                        data-tip={f.getTooltipNewOrdersOffers(MMOOCounters.numberOfNewOrders, this.props.globals.locale)}
                      >
                        {MMOOCounters.numberOfNewOrders}
                        <ReactTooltip
                          id="numberOfNewOrders"
                          place="bottom"
                          type="info"
                          effect="solid"
                        />
                      </span>
                    }
                    {MMOOCounters.numberOfNewOrdersMessages > 0 &&
                      <span 
                        className="numberOfNewOrdersMessages"
                        data-for="numberOfNewOrdersMessages"
                        data-tip={f.getTooltipNewOrderMessages(MMOOCounters.numberOfNewOrdersMessages, this.props.globals.locale)}
                      >
                        {MMOOCounters.numberOfNewOrdersMessages}
                        <ReactTooltip
                          id="numberOfNewOrdersMessages"
                          place="bottom"
                          type="info"
                          effect="solid"
                        />
                      </span>
                    }
                  </NavLink>
                </li>
              }
              <li ref={input => { this.node = input }}>
                <a href="#toggle" onClick={this.onToggleFlags}>
                  <span className={`flag-icon flag-icon-${localeFl}`}></span>
                </a>
                <ul className={`${activeFlags} lang-menu`}>
                  {languages.map((locale, i) => {
                    const printLang = locale !== 'en' ? locale : 'gb'
                    return (
                      <li key={locale}>
                        <button
                          className="langButton"
                          // onClick={() => { 
                          //   this.onToggleFlags(); 
                          //   // return setLocale(locale)
                          //   return dispatch(a.setLocale(locale))
                          // } }
                          onClick={this.onClickLanguage(locale)}
                          // to={`/${locale}${this.props.location.pathname.substring(3)}`}
                        >
                          <span className={`flag-icon flag-icon-${printLang}`}></span>
                        </button>
                      </li>
                    )
                  })}
                </ul>
              </li>
              {login && 
                <li className="profile" ref={input => { this.node2 = input }}>
                  <img onClick={this.showProfileMenu} src={avatar} alt="" />
                  <i onClick={this.showProfileMenu} className="fa fa-angle-down"></i>
                  <ul
                    style={{ display: this.state.showProfileMenu ? "block" : "none" }}
                    
                  >
                    <li><NavLink to={`/${locale}/users/${encodeURIComponent(login)}/`} exact>{_("Profile")}</NavLink></li>
                    <li><NavLink to={`/${locale}/users/${encodeURIComponent(login)}/settings/main/`} exact>{_("Settings")}</NavLink></li>
                    <li>
                      <NavLink 
                        to={`/${locale}/logout/`}
                        onClick={this.onClickLogout}
                      >
                        {_("Log out")}
                      </NavLink>
                    </li>
                  </ul>
                </li>
              }
              {!login && <li><NavLink to={`/${locale}/login/`} exact>{_("Log in")}</NavLink></li>}
              {!login && <li><NavLink to={`/${locale}/registration/`} exact>{_("Registration")}</NavLink></li>}
            </ul>

            <ul className={ `${active} menu` }>
              <li className="logo">
                <NavLink to={`/`} exact className="logo">
                  <img src="/img/logo.png" alt="" />
                </NavLink>
              </li>
              <li className="projects"><NavLink to={`/${locale}/projects/`} exact>{_("Projects")}</NavLink></li>
              <li className="freelancers"><NavLink to={`/${locale}/freelancers/`} exact>{_("Freelancers")}</NavLink></li>
              <li className="portfolio"><NavLink to={`/${locale}/portfolio/`} exact>{_("Portfolio")}</NavLink></li>
              <li className="publishProject">
                <div className="buttonPublish">
                  <Link
                    to={`/${this.props.globals.locale}/publish/`}
                  >
                    {_("Publish project")}
                  </Link>
                </div>
              </li>
              {login &&
                <li className="messages">
                  <NavLink
                    className="messages"
                    to={`/${locale}/messages/`}
                    exact
                  >
                    {_("Messages")}
                    {MMOOCounters.numberOfNewPrivateMessages > 0 &&
                      <span 
                        className="numberOfNewPrivateMessages"
                        data-for="numberOfNewPrivateMessages"
                        data-tip={f.getTooltipPrivateMessages(MMOOCounters.numberOfNewPrivateMessages, this.props.globals.locale)}
                      >
                        {MMOOCounters.numberOfNewPrivateMessages}
                        <ReactTooltip
                          id="numberOfNewPrivateMessages"
                          place="bottom"
                          type="info"
                          effect="solid"
                        />
                      </span>
                    }
                  </NavLink>
                </li>
              }
              {login &&
                <li className="myprojects">
                  <NavLink
                    className="myprojects"
                    to={`/${locale}/myprojects/`}
                    exact
                  >
                    {_("My projects")}
                    {MMOOCounters.numberOfNewMyProjectsOffers > 0 &&
                      <span 
                        className="numberOfNewMyProjectsOffers"
                        data-for="numberOfNewMyProjectsOffers"
                        data-tip={f.getTooltipNewProjectOffers(MMOOCounters.numberOfNewMyProjectsOffers, this.props.globals.locale)}
                      >
                        {MMOOCounters.numberOfNewMyProjectsOffers}
                        <ReactTooltip
                          id="numberOfNewMyProjectsOffers"
                          place="bottom"
                          type="info"
                          effect="solid"
                        />
                      </span>
                    }
                    {MMOOCounters.numberOfNewMyProjectsOffersMessages > 0 &&
                      <span 
                        className="numberOfNewMyProjectsOffersMessages"
                        data-for="numberOfNewMyProjectsOffersMessages"
                        data-tip={f.getTooltipNewProjectMessages(MMOOCounters.numberOfNewMyProjectsOffersMessages, this.props.globals.locale)}
                      >
                        {MMOOCounters.numberOfNewMyProjectsOffersMessages}
                        <ReactTooltip
                          id="numberOfNewMyProjectsOffersMessages"
                          place="bottom"
                          type="info"
                          effect="solid"
                        />
                      </span>
                    }
                  </NavLink>
                </li>
              }
              {login && 
                <li className="offers">
                  <NavLink
                    className="offers"
                    to={`/${locale}/offers/`}
                    exact
                  >
                    {_("Offers")}
                    {MMOOCounters.numberOfNewOffersMessages > 0 &&
                      <span 
                        className="numberOfNewOffersMessages"
                        data-for="numberOfNewOffersMessages"
                        data-tip={f.getTooltipNewOfferMessages(MMOOCounters.numberOfNewOffersMessages, this.props.globals.locale)}
                      >
                        {MMOOCounters.numberOfNewOffersMessages}
                        <ReactTooltip
                          id="numberOfNewOffersMessages"
                          place="bottom"
                          type="info"
                          effect="solid"
                        />
                      </span>
                    }
                  </NavLink>
                </li>
              }
              {login &&
                <li className="orders">
                  <NavLink
                    className="orders"
                    to={`/${locale}/orders/`}
                    exact
                  >
                    {_("Orders")}
                    {MMOOCounters.numberOfNewOrders > 0 &&
                      <span 
                        className="numberOfNewOrders"
                        data-for="numberOfNewOrders"
                        data-tip={f.getTooltipNewOrdersOffers(MMOOCounters.numberOfNewOrders, this.props.globals.locale)}
                      >
                        {MMOOCounters.numberOfNewOrders}
                        <ReactTooltip
                          id="numberOfNewOrders"
                          place="bottom"
                          type="info"
                          effect="solid"
                        />
                      </span>
                    }
                    {MMOOCounters.numberOfNewOrdersMessages > 0 &&
                      <span 
                        className="numberOfNewOrdersMessages"
                        data-for="numberOfNewOrdersMessages"
                        data-tip={f.getTooltipNewOrderMessages(MMOOCounters.numberOfNewOrdersMessages, this.props.globals.locale)}
                      >
                        {MMOOCounters.numberOfNewOrdersMessages}
                        <ReactTooltip
                          id="numberOfNewOrdersMessages"
                          place="bottom"
                          type="info"
                          effect="solid"
                        />
                      </span>
                    }
                  </NavLink>
                </li>
              }
            </ul>
          </nav>
        </div>

        <div
          className="preloader"
          style={{ display: (this.isFetching) ? 'flex' : 'none' }}
        >
          <div className="background"></div>
          <img src="/img/preloaders/3.gif" alt="" />
        </div>
      </div>
    ) 
  }
}

const mapStateToProps = state => {
  // const { login, avatar } = state.globals
  // return { login, avatar }
  const { globals } = state
  return { globals }
}

Header = connect(mapStateToProps)(Header)
Header = withRouter(Header)
Header = withStone(Header)



export default Header