import Cookie from 'js-cookie'

// // 1. locale initialization
// let locale = window.localStorage.getItem('locale')
// if (!locale) {
//   window.localStorage.setItem('locale', 'en')
//   locale = 'en'
// }

let locales = {
  "en": true,
  "ru": true,
  "ua": true,
  "in": true,
  "cn": true,
  "es": true,
  "de": true,
  "pl": true,
  "fr": true
}

// #############################
// 1. locale initialization
// #############################
let locale = window.location.pathname.substr(1,2)
// if (!locale || (locale !== 'en' && locale !== 'ru' && locale !== 'ua' && locale !== 'in' && locale !== 'cn' && locale !== 'es' && locale !== 'de' && locale !== 'pl' && locale !== 'fr')) {
if (!locales[locale]) {
  // console.log('not exists')
  // 1.2 get from the localStorage
  locale = window.localStorage.getItem('locale')
  if (!locale) {
    window.localStorage.setItem('locale', 'en')
    locale = 'en'
    Cookie.set('locale', 'en', { expires: 1000 })
  }
// } else if (locale === 'en' || locale === 'ru' || locale === 'ua' || locale === 'in' || locale === 'cn' || locale === 'es' || locale === 'de' || locale === 'pl' || locale === 'fr') {
} else {
  window.localStorage.setItem('locale', locale)
  Cookie.set('locale', locale, { expires: 1000 })
}

// if (window.location.pathname === '/') {
//   console.log('redirect')
//   window.location.href = `/${locale}/`
// }

// console.log('locale = ', locale)

// 2. user initialization
let login = ''
let firstName = ''
let secondName = ''
let numberOfPositiveReviews = ''
let numberOfNeutralReviews = ''
let numberOfNegativeReviews = ''
let role = ''
let token = ''
let id = ''
let avatar = ''
let viewEditProfile = ''
// let expiresIn = +window.localStorage.getItem('expiresIn') || ''

// if (expiresIn > +new Date) {
//   login = window.localStorage.getItem('login') || ''
//   firstName = window.localStorage.getItem('firstName') || ''
//   secondName = window.localStorage.getItem('secondName') || ''
//   numberOfPositiveReviews = window.localStorage.getItem('numberOfPositiveReviews') || ''
//   numberOfNeutralReviews = window.localStorage.getItem('numberOfNeutralReviews') || ''
//   numberOfNegativeReviews = window.localStorage.getItem('numberOfNegativeReviews') || ''
//   token = window.localStorage.getItem('token') || ''
//   id = window.localStorage.getItem('id') || ''
//   avatar = window.localStorage.getItem('avatar') || ''
// }

// let rememberMe = window.localStorage.getItem('rememberMe')
// console.log('rememberMe = ', rememberMe)

// if (rememberMe === 'true') {
//   console.log('remember1')
  token = window.localStorage.getItem('token') || ''
  id = window.localStorage.getItem('id') || ''
  login = window.localStorage.getItem('login') || ''
  avatar = window.localStorage.getItem('avatar') || ''
  firstName = window.localStorage.getItem('firstName') || ''
  secondName = window.localStorage.getItem('secondName') || ''
  numberOfPositiveReviews = window.localStorage.getItem('numberOfPositiveReviews') || ''
  numberOfNeutralReviews = window.localStorage.getItem('numberOfNeutralReviews') || ''
  numberOfNegativeReviews = window.localStorage.getItem('numberOfNegativeReviews') || ''
  role = window.localStorage.getItem('role') || ''
// }
// } else {
//   console.log('remember2')
//   token = window.sessionStorage.getItem('token') || ''
//   id = window.sessionStorage.getItem('id') || ''
//   login = window.sessionStorage.getItem('login') || ''
//   avatar = window.sessionStorage.getItem('avatar') || ''
//   firstName = window.sessionStorage.getItem('firstName') || ''
//   secondName = window.sessionStorage.getItem('secondName') || ''
//   numberOfPositiveReviews = window.sessionStorage.getItem('numberOfPositiveReviews') || ''
//   numberOfNeutralReviews = window.sessionStorage.getItem('numberOfNeutralReviews') || ''
//   numberOfNegativeReviews = window.sessionStorage.getItem('numberOfNegativeReviews') || ''
// }
viewEditProfile = window.sessionStorage.getItem('viewEditProfile') || 'Edit'

if (avatar === 'null') {
  avatar = null
}

const initialState = {
  id: id,
  login: login,
  firstName: firstName,
  secondName: secondName,
  numberOfPositiveReviews: +numberOfPositiveReviews,
  numberOfNeutralReviews: +numberOfNeutralReviews,
  numberOfNegativeReviews: +numberOfNegativeReviews,
  role: role,
  token: token,
  locale: locale,
  // expiresIn: expiresIn,
  rememberMe: false,
  "avatar": avatar,
  "messageRedirect": {},
  "projectForm": {},
  "offerForm": {},
  "orderForm": {},
  viewEditProfile
}

// console.log('initialState = ', initialState)

export default (state = initialState, action) => {
  switch (action.type) {
    // case 'SET_LOCALE': {
    //   const { locale } = action.payload
    //   return {
    //     ...state,
    //     locale
    //   }
    // }
    case 'SET_ID_LOGIN_TOKEN': {
      const obj = action.payload
      // let { expiresIn } = action.payload
      // expiresIn = +new Date + expiresIn * 1000
      return {
        ...state,
        ...obj,
        // expiresIn
      }
    }
    case 'REMOVE_ID_LOGIN_TOKEN': {
      return {
        ...state,
        id: '',
        login: '',
        token: '',
        // expiresIn: '',
        avatar: ''
      }
    }
    case 'RECEIVE_TOKEN': {
      let { token, expiresIn } = action.payload
      expiresIn = +new Date() + expiresIn * 1000
      return {
        ...state,
        token,
        expiresIn
      }
    }
    case 'UPDATE_LOGIN': {
      let { login } = action.payload
      return {
        ...state,
        login
      }
    }
    case 'UPDATE_AVATAR': {
      let { avatar } = action.payload
      return {
        ...state,
        avatar
      }
    }
    case 'SET_MESSAGE_REDIRECT': {
      let { messageRedirectId } = action.payload
      return {
        ...state,
        messageRedirect: { messageRedirectId }
      }
    }
    case 'REMOVE_MESSAGE_REDIRECT': {
      return {
        ...state,
        messageRedirect: {}
      }
    }
    case 'SET_PROJECT_FORM': {
      let { title, description, specializations, budget, deadline, language } = action.payload
      return {
        ...state,
        projectForm: { title, description, specializations, budget, deadline, language }
      }
    }
    case 'REMOVE_PROJECT_FORM': {
      return {
        ...state,
        projectForm: {}
      }
    }
    case 'SET_OFFER_FORM': {
      let { toId, description, budget, deadline, projectId } = action.payload
      return {
        ...state,
        offerForm: { toId, description, budget, deadline, projectId }
      }
    }
    case 'REMOVE_OFFER_FORM': {
      return {
        ...state,
        offerForm: {}
      }
    }
    case 'SET_ORDER_FORM': {
      let { title, description, budget, deadline, freelancerId } = action.payload
      return {
        ...state,
        orderForm: { title, description, budget, deadline, freelancerId }
      }
    }
    case 'REMOVE_ORDER_FORM': {
      return {
        ...state,
        orderForm: {}
      }
    }
    case 'CHANGE_VIEW_EDIT_PROFILE': {
      let { viewEditProfile } = action.payload
      return {
        ...state,
        viewEditProfile
      }
    }

    default: return state
  }
}